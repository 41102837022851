import React from "react";
import CustomHeader from "../../app/common/elements/CustomHeader";
import ServicesCard from "../../app/common/elements/ServicesCard";

export default function ServicesPage() {
    return (
        <div className="bg-lk_white_backround">
            <CustomHeader heading="SERVICES" breadcrumbs={[
                {name: "Home", href: '/', count: 1},
                {name: "Online Ordering", href: '/services', count: 2},
            ]}/>

            <div className="flex flex-wrap justify-center py-10 max-w-7xl m-auto">
                <ServicesCard image="/images/services/lakato_wholesale_link.jpg" text="Wholesale" href="/services/wholesale" />
                <ServicesCard image="/images/services/lakato_warehousing_and_distribution_link.jpg" text="Warehousing & Distribution" href="/services/warehousing-and-distribution" />
                <ServicesCard image="/images/services/lakato_repacking_link.jpg" text="Repacking" href="/services/repacking" />
                <ServicesCard image="/images/services/lakato_logistics_link.jpg" text="Logistics" href="/services/logistics" />
            </div>
        </div>
    )
}
