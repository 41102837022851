import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

export default function Footer() {
    const { pathname } = useLocation();

    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
    }

    const { currentUser } = useSelector((state) => state.auth);

    let loggedOutNavData = {
        main: [
            { name: "Home", href: "/", current: pathname === "/" ? true : false },
            { name: "About Us", href: "/about-us", current: pathname === "/" ? true : false },
            { name: "Products", href: "/products", current: pathname === "/" ? true : false },
            { name: "Warehousing & Distribution", href: "/services/warehousing-and-distribution", current: pathname === "/" ? true : false },
            { name: "Online Ordering", href: "/online-ordering", current: pathname === "/" ? true : false },
            {
                name: "Contact Us",
                href: "/contact-us",
                current: pathname === "/contact-us" ? true : false,
            },
        ],
        sub: [
            { name: "Legal & Compliance", href: "/legal", current: pathname === "/legal" ? true : false },
            { name: "Privacy Policy", href: "/legal", current: pathname === "/legal" ? true : false },
            { name: "Terms of Service", href: "/legal", current: pathname === "/legal" ? true : false },
        ]
    }

    let loggedInNavigation = {
        main: [
            { name: "Home", href: "/", current: pathname === "/" ? true : false },
            { name: "About Us", href: "/about-us", current: pathname === "/" ? true : false },
            { name: "Services", href: "/services", current: pathname === "/" ? true : false,
                children: [
                    {name: "Wholesale", href: "/services/wholesale", current: pathname === "/services/wholesale" ? true : false},
                    {name: "Warehousing & Distribution", href: "/services/warehousing-and-distribution", current: pathname === "/services/warehousing-and-distribution" ? true : false},
                    {name: "Repacking", href: "/services/repacking", current: pathname === "/services/repacking" ? true : false},
                    {name: "Logistics", href: "/services/logistics", current: pathname === "/services/logistics" ? true : false},
                ]
             },
            { name: "Online Ordering", href: "/online-ordering", current: pathname === "/" ? true : false },
            { name: "Products", href: "/products", current: pathname === "/" ? true : false },
            { name: "Contact Us", href: "/contact-us", current: pathname === "/contact-us" ? true : false, },
        ],
        sub: [
            { name: "Legal & Compliance", href: "/legal", current: pathname === "/legal" ? true : false },
            { name: "Privacy Policy", href: "/legal", current: pathname === "/legal" ? true : false },
            { name: "Terms of Service", href: "/legal", current: pathname === "/legal" ? true : false },
        ]
    };

    const navigation = !currentUser ? loggedOutNavData : loggedInNavigation;

    

    return (
        <footer className="bg-white">
            <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
                <img
                    className="h-8 w-auto m-auto"
                    src="/images/logo.svg"
                    alt="Lakato"
                />
                <nav className="-mx-5 mt-8 lg:mt-12 mb-10 lg:mb-20 justify-center grid grid-cols-2 gap-2 md:grid-cols-3 lg:flex lg:flex-wrap" aria-label="Footer">
                    {navigation.main.map((item) => {

                        if (item.children) {
                            return (
                                <div className="dropdown inline px-4 tracking-wide relative" key={item.name}>
                                    <Link
                                        className={classNames(
                                            item.current
                                                ? "text-lk_dark_grey_button"
                                                : "text-lk_grey_two hover:text-lk_dark_grey_button",
                                            "py-2 px-3 inline-flex items-center text-sm font-medium uppercase"
                                        )}
                                        as="a"
                                        to={item.href}
                                        // onClick={() => {close()}}
                                        aria-current={
                                            item.current ? "page" : undefined
                                        }
                                    >
                                        {item.name}{' '}<FontAwesomeIcon className="ml-2" icon={faChevronRight} />
                                    </Link>
                                    <div className="dropdown-menu top-6 absolute hidden h-auto pt-4 z-10">
                                        <ul className="block w-full bg-white shadow p-2">
                                            {item.children.map((child) => (
                                                <li className="py-1" key={child.name}>
                                                    <Link
                                                        className={classNames(
                                                            child.current
                                                                ? "text-lk_dark_grey_button"
                                                                : "text-lk_grey_two hover:text-lk_dark_grey_button",
                                                            "py-0 px-3 inline-flex items-center text-sm font-medium uppercase w-40"
                                                        )}
                                                        as="a"
                                                        to={child.href}
                                                        // onClick={() => {close()}}
                                                        aria-current={
                                                            child.current ? "page" : undefined
                                                        }
                                                    >
                                                        {child.name}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                
                            )
                        } else if(item.name !== "Home") {
                            return (
                                <Link
                                    key={item.name}
                                    className={classNames(
                                        item.current
                                            ? "text-lk_dark_grey_button"
                                            : "text-lk_grey_two hover:text-lk_dark_grey_button",
                                        "py-2 px-3 inline-flex items-center text-sm font-medium uppercase"
                                    )}
                                    as="a"
                                    to={item.href}
                                    // onClick={() => {close()}}
                                    aria-current={
                                        item.current ? "page" : undefined
                                    }
                                >
                                    {item.name}
                                </Link>
                            )
                        }
                        <div key={item.name} className="px-5 py-2">
                            {/* <a href={item.href} className="text-lk_black_footer hover:text-lk_grey_footer uppercase text-sm">
                                {item.name}
                            </a> */}
                            <Link
                                key={item.name}
                                className="text-lk_black_footer hover:text-lk_grey_footer uppercase text-sm"
                                as="a"
                                to={item.href}
                                aria-current={
                                    item.current ? "page" : undefined
                                }
                            >
                                {item.name}
                            </Link>
                        </div>
                    })}
                </nav>
                <hr/>
                <div className="mt-4 lg:mt-8 justify-around sm:space-x-6 grid grid-cols-1 gap-4 sm:flex sm:flex-wrap">
                    <p className="mt-4 lg:mt-0 text-sm text-lk_grey_footer order-2 lg:order-1">&copy; {(new Date().getFullYear())} Lakato (PTY) Ltd |  All Rights Reserved</p>
                    <div className="grid grid-cols-1 gap-4 sm:flex sm:justify-evenly order-1 lg:order-2 lg:space-x-6">
                        {navigation.sub.map((item) => (
                            <a key={item.name} href={item.href} className="text-lk_black_footer hover:text-lk_grey_footer uppercase text-sm">
                                {item.name}
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        </footer>
    );
}
