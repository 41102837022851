import React, { useState, useEffect } from "react";
import AdminSearchForm from "../../../forms/AdminSearchForm";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";
import { useHistory, Link } from "react-router-dom";

export default function Orders() {
    const { currentUser } = useSelector((state) => state.auth);
    const history = useHistory();

    const [orders, setOrders] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [recordCount, setRecordCount] = useState(0);
    const [sortBy, setSortBy] = useState("EntrySystemDate"); // Default sorting
    const [sortDirection, setSortDirection] = useState("desc"); // Default sorting direction
    const [pageSizeValue, setPageSizeValue] = useState(10);
    const [filterValue, setFilterValue] = useState("");

    useEffect(() => {
        getOrders();
    }, [sortBy, sortDirection, filterValue, pageNumber, pageSizeValue]);

    async function getOrders() {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}all-orders`,
                {
                    userId: currentUser.id,
                    customer_code:
                        currentUser.isCustomer === 1 &&
                        currentUser.customer_code !== null
                            ? currentUser.customer_code
                            : currentUser.customer_code === null ||
                              currentUser.customer_code.split(",").length > 1
                            ? "None"
                            : currentUser.customer_code,
                    pageNumber: pageNumber,
                    pageSizeValue: pageSizeValue,
                    sortBy: sortBy,
                    sortDirection: sortDirection,
                    filterItem: filterValue,
                },
                { headers: { Authorization: `Bearer ${currentUser.token}` } }
            );

            const orderList = response.data[0];
            const resultOrder = response.data[2].map((a) => a.order_no);
            const ownOrders = response.data[2];
            setRecordCount(response.data[1][0][""]);

            let allOrders = [];

            orderList.forEach((order) => {
                // Determine the status name
                let statusName = "";
                switch (order.OrderStatus) {
                    case "1":
                        statusName = "Open Order";
                        break;
                    case "2":
                        statusName = "Open Back Order";
                        break;
                    case "3":
                        statusName = "Released back order";
                        break;
                    case "4":
                        statusName = "In warehouse";
                        break;
                    case "8":
                        statusName = "To invoice";
                        break;
                    case "F":
                        statusName = "Forward order";
                        break;
                    case "S":
                        statusName = "In Suspense";
                        break;
                    case "9":
                        statusName = "Complete";
                        break;
                    case "\\":
                        statusName = "Cancelled";
                        break;
                    default:
                        statusName = "Unknown";
                }

                let status = "";
                if (order.ActiveFlag === "N") {
                    status = `Inactive (${statusName})`;
                } else if (order.CancelledFlag === "Y") {
                    status = `Cancelled (${statusName})`;
                } else {
                    status = `(${statusName})`;
                }

                // Determine who placed the order
                let placed_by = "Inhouse";
                if (resultOrder.includes(order.SalesOrder)) {
                    ownOrders.forEach((ownOrder) => {
                        if (ownOrder.order_no === order.SalesOrder) {
                            placed_by =
                                ownOrder.user.is_sales === 1
                                    ? `Rep - ${ownOrder.user.email}`
                                    : `Customer - ${ownOrder.user.email}`;
                        }
                    });
                }

                allOrders.push({
                    SalesOrder: order.SalesOrder,
                    CustomerPoNumber: order.CustomerPoNumber || "",
                    EntrySystemDate: moment(order.EntrySystemDate).format(
                        "YYYY-MM-DD"
                    ),
                    OrderStatus: status,
                    Total: `R ${order.orderCost}`,
                    PlacedBy: placed_by,
                });
            });

            setOrders(allOrders);
        } catch (error) {
            console.error("Error fetching orders:", error);
        }
    }

    function getStatusName(orderStatus) {
        const statusMap = {
            1: "Open Order",
            2: "Open Back Order",
            3: "Released back order",
            4: "In warehouse",
            8: "To invoice",
            9: "Complete",
            F: "Forward order",
            S: "In Suspense",
            "\\": "Cancelled",
        };
        return statusMap[orderStatus] || "Unknown";
    }

    function getPlacedBy(order, ownOrders) {
        const placedBy = ownOrders.find((o) => o.order_no === order.SalesOrder);
        return placedBy
            ? placedBy.user.is_sales === 1
                ? `Rep - ${placedBy.user.email}`
                : `Customer - ${placedBy.user.email}`
            : "Inhouse";
    }

    const handleSort = (field) => {
        const newSortDirection =
            sortBy === field && sortDirection === "asc" ? "desc" : "asc";
        setSortBy(field);
        setSortDirection(newSortDirection);
    };

    return (
        <div className="bg-gray-50">
            <AdminSearchForm
                heading="Orders"
                breadcrumbs={[
                    { name: "Home", href: "/", count: 1 },
                    { name: "Orders", href: "/orders", count: 2 },
                ]}
            />

            {/* <AdminNav cartTotal={cartTotal}/> */}
            <br />
            <br />

            <div className="container mx-auto relative pt-20">
                <div className="flex absolute top-0 right-0 mt-5">
                    <Link
                        as="a"
                        to={"/backorders"}
                        className="hover:text-lk_dark_grey_button_hover"
                    >
                        <button className="px-10 py-2 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_red hover:bg-lk_red_button_hover uppercase mx-auto">
                            View all items on Backorder
                        </button>
                    </Link>
                </div>
                <label className="mt-2 mr-2">Filter: </label>
                <input
                    type="text"
                    onChange={(v) => setFilterValue(v.target.value)}
                />
                <br />
                <small>
                    When filtering by date please enter in YYYY-MM-DD format
                </small>
            </div>

            <div className="container mx-auto">
                <table
                    className="mb-10 w-full"
                    style={{
                        borderCollapse: "separate",
                        borderSpacing: "0 1em",
                    }}
                >
                    <thead className="bg-gray-200">
                        <tr>
                            <th
                                className="px-2 py-5 font-light text-sm cursor-pointer"
                                onClick={() => handleSort("SalesOrder")}
                            >
                                Order No.
                            </th>
                            <th
                                className="px-2 py-5 font-light text-sm cursor-pointer"
                                onClick={() => handleSort("CustomerPoNumber")}
                            >
                                Product Order Number
                            </th>
                            <th
                                className="px-2 py-5 font-light text-sm cursor-pointer"
                                onClick={() => handleSort("EntrySystemDate")}
                            >
                                Order Date
                            </th>
                            <th
                                className="px-2 py-5 font-light text-sm cursor-pointer"
                                onClick={() => handleSort("OrderStatus")}
                            >
                                Order Status
                            </th>
                            <th className="px-2 py-5 font-light text-sm">
                                Placed By
                            </th>
                            <th className="px-2 py-5 font-light text-sm">
                                Total
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders.map((order, index) => (
                            <tr
                                key={index}
                                className="bg-white mt-5 shadow-lg cursor-pointer"
                                onClick={() =>
                                    history.push(`/order/${order.SalesOrder}`)
                                }
                            >
                                <td className="mx-auto text-center relative px-2 py-2">
                                    {order.SalesOrder}
                                </td>
                                <td className="mx-auto text-center relative px-2 py-2">
                                    {order.CustomerPoNumber}
                                </td>
                                <td className="mx-auto text-center relative px-2 py-2">
                                    {order.EntrySystemDate}
                                </td>
                                <td className="mx-auto text-center relative px-2 py-2">
                                    {order.OrderStatus}
                                </td>
                                <td className="mx-auto text-center relative px-2 py-2">
                                    {order.PlacedBy}
                                </td>
                                <td className="mx-auto text-center relative px-2 py-2">
                                    {order.Total}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <nav
                    className="px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 bg-gray-50"
                    aria-label="Pagination"
                >
                    <div className="hidden sm:block">
                        <p className="text-sm text-gray-700">
                            Page{" "}
                            <span className="font-medium">
                                {pageNumber + 1}
                            </span>{" "}
                            of{" "}
                            <span className="font-medium">
                                {Math.ceil(recordCount / pageSizeValue)}
                            </span>
                        </p>
                        <p className="text-sm text-gray-700">
                            Showing{" "}
                            <span className="font-medium">
                                {pageNumber === 0
                                    ? "1"
                                    : pageNumber * pageSizeValue + 1}
                            </span>{" "}
                            to{" "}
                            <span className="font-medium">
                                {Math.min(
                                    (pageNumber + 1) * pageSizeValue,
                                    recordCount
                                )}
                            </span>{" "}
                            of{" "}
                            <span className="font-medium">{recordCount}</span>{" "}
                            results
                        </p>
                    </div>
                    <div className="flex-1 flex justify-between sm:justify-end">
                        <button
                            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                            onClick={() =>
                                setPageNumber((prev) => Math.max(prev - 1, 0))
                            }
                            disabled={pageNumber === 0}
                        >
                            Previous
                        </button>
                        <button
                            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                            onClick={() => setPageNumber((prev) => prev + 1)}
                            disabled={
                                (pageNumber + 1) * pageSizeValue >= recordCount
                            }
                        >
                            Next
                        </button>
                    </div>
                </nav>
            </div>
        </div>
    );
}
