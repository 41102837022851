import React, { Fragment, useEffect, useState } from 'react'
// import { useHistory } from "react-router-dom";
import { closeModal } from "../../app/common/modals/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCheckCircle, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { Dialog, Transition } from '@headlessui/react';
import { faExclamation, faShoppingCart, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import CustomButton from '../../app/common/form/CustomButton';
import axios from 'axios';
import CustomText from '../../app/common/form/CustomText';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from "react-toastify";
import ReactTooltip from "react-tooltip";

export default function PromotionDiscount(props) {
    // let history = useHistory();
    const dispatch = useDispatch();
    const [open] = useState(true);
    const { currentUser } = useSelector((state) => state.auth);
    const [cartTotal, setCartTotal] = useState([])
    const [products, setProducts] = useState([])
    const [total, setTotal] = useState("1");
    const [maxTotal, setMaxTotal] = useState("");
    const [purchaseTotal, setPurchaseTotal] = useState(0);
    const [freeTotal, setFreeTotal] = useState(0);

    const [specialQty, setSpecialQty] = useState(0);
    const [specialType, setSpecialType] = useState(0);
    const [specialSize, setSpecialSize] = useState(0);

    const [currentPromotionTotal, setCurrentPromotionTotal] = useState([]);
    const [currentPromotionSC, setCurrentPromotionSC] = useState([]);
    // const [currentSelected, setCurrentSelected] = useState(0);

    const [buttonDisabled, setButtonDisabled] = useState(true);

    let disabledCss = "lkbutton bg-red-600 w-60 border_radius_10 opacity-50 cursor-not-allowed";
    let activeCss = "lkbutton bg-red-600 hover:bg-red-800 w-60 border_radius_10 disabled:opacity-50";

    let promotion = props.Promotion;

    console.log(promotion)
    

    useEffect(() => {
        setCartTotal(parseFloat(window.sessionStorage.getItem("itemsInBasket")));
        getProductsSpecial();
    }, []);
  
    async function getProductsSpecial(){

        if(promotion && promotion.col15){
            if((promotion.col15).length == 1){
                setSpecialQty(promotion.col15[0].rule_qty)
                setSpecialType(promotion.col15[0].rule_type)
                setSpecialSize(promotion.col15[0].rule_size)
            }
        }
        
        let specialList = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}special-products`,
            headers: { 
                Authorization: `Bearer ${currentUser.token}`
            },
            data:{
                productsReq: promotion.col11,
                productsFree: promotion.col12,
                products: [promotion.col11, promotion.col12].flat(),
                customer_code: currentUser.isCustomer === 1 && currentUser.customer_code !== null ? currentUser.customer_code : currentUser.customer_code === null || currentUser.customer_code.split(",").length > 1 ? "None" : currentUser.customer_code,
                branch: currentUser.branch,
                userId: currentUser.id,
            }
        });

        

        let reqItems = specialList.data[0];
        let cartItems = specialList.data[1];
        let prodDataSubCode = specialList.data[2];

        let allCodes = [];

        prodDataSubCode.forEach(function (subCode) {
            allCodes.push(subCode.stock_code)
        });

        let allItems = [];
        
        await Object.keys(reqItems).forEach(async function (index) {
            let total = 1;
            let inCart = "no";
            await Object.keys(cartItems).forEach(function (key) {
                if(reqItems[index].StockCode === cartItems[key].stock_code){
                    total = cartItems[key].qty
                    inCart = "yes";
                }
            })

            reqItems[index].display = "";

            if(prodDataSubCode.length > 0 && allCodes.includes(reqItems[index].StockCode)){
                console.log('sd')
                prodDataSubCode.forEach(function (subCode) {
                    if (subCode.stock_code == reqItems[index].StockCode) {
                        reqItems[index].display = subCode.custom_code
                    }
                });
            }

            reqItems[index].qty = total;
            reqItems[index].inCart = inCart
            allItems.push(reqItems[index])
        })

        setProducts(allItems);
    }

    async function claimDeal(){

        let itemId = "promomotion_"+promotion.col0;

        await Object.keys(currentPromotionTotal).forEach(function (key) {
            let allItems = currentPromotionTotal[key][1];
            for (let i = 0; i < parseFloat(allItems); i++) {
                addItemToCart(currentPromotionTotal[key][0])
            }
        })

        dispatch(closeModal());

        // promotionItemsInCart

    }

    async function addItemToCart(itemId, qty, qtyNo){
        let newQty = parseFloat(document.getElementById(qtyNo).value);
        itemId = itemId.trim();

        let cartItem = await axios({
          method: "post",
          url: `${process.env.REACT_APP_API_URL}add-items-to-cart`,
          headers: { Authorization: `Bearer ${currentUser.token}` },
          data: {
            StockCode: itemId,
            id: currentUser.id,
            qty: newQty,
            auto: 0,
            customer_code: currentUser.customer_code,
          },
        });

        if(cartItem.data == 1){
            let CurrentCartAmount = window.sessionStorage.getItem("itemsInBasket")
            let NewAmount = parseFloat(CurrentCartAmount) + 1
            window.sessionStorage.setItem("itemsInBasket", NewAmount);
        }

        toast.success("Item Added to Cart", {position: "bottom-right", autoClose: 5000, closeOnClick: true,});

        await getProductsSpecial();

        // let prevItemClicked = JSON.parse(
        //     window.sessionStorage.getItem("itemsClicked")
        // );

        // if (prevItemClicked !== null) {

        //     window.sessionStorage.setItem(
        //         "itemsClicked",
        //         JSON.stringify([...prevItemClicked, itemId])
        //     );
        // } else {
        //     window.sessionStorage.setItem(
        //         "itemsClicked",
        //         JSON.stringify(itemId)
        //     );
        // }


        // let prevItemIds = JSON.parse(
        //     window.sessionStorage.getItem("itemsIdsInBasket")
        // );

        // if (!prevItemIds.includes(itemId)) {
        //     window.sessionStorage.setItem(
        //         "itemsIdsInBasket",
        //         JSON.stringify([...prevItemIds, itemId])
        //     );

        //     let CurrentCartAmount =
        //         window.sessionStorage.getItem("itemsInBasket");
        //     let NewAmount = parseFloat(CurrentCartAmount) + 1;
        //     window.sessionStorage.setItem("itemsInBasket", NewAmount);
        //     setCartTotal(NewAmount);

        //     let prevPromoItems = JSON.parse(
        //         window.sessionStorage.getItem("promotionItemsInCart")
        //     );
        //     if (prevPromoItems !== null) {
        //         window.sessionStorage.setItem(
        //             "promotionItemsInCart",
        //             JSON.stringify([...prevPromoItems, [1, itemId, promotion.col0]])
        //         );
        //     } else {
        //         window.sessionStorage.setItem(
        //             "promotionItemsInCart",
        //             JSON.stringify([[1, itemId, promotion.col0]])
        //         );
        //     }
        // }else{
        //     let totalItemsClicked = JSON.parse(
        //         window.sessionStorage.getItem("itemsClicked")
        //     );
        //     let thisItem = 0;

        //     await Object.keys(totalItemsClicked).forEach(function (key) {
        //         if (totalItemsClicked[key] === itemId) {
        //             thisItem += 1;
        //         }
        //     });

        //     let prevItemTotals = JSON.parse(
        //         window.sessionStorage.getItem("totalItemsInBasket")
        //     );
        //     if (prevItemTotals !== null) {
        //         window.sessionStorage.setItem(
        //             "totalItemsInBasket",
        //             JSON.stringify([...prevItemTotals, [thisItem, itemId]])
        //         );
        //     } else {
        //         window.sessionStorage.setItem(
        //             "totalItemsInBasket",
        //             JSON.stringify([[thisItem, itemId]])
        //         );
        //     }

        //     let prevPromoItems = JSON.parse(
        //         window.sessionStorage.getItem("promotionItemsInCart")
        //     );
        //     if (prevPromoItems !== null) {
        //         window.sessionStorage.setItem(
        //             "promotionItemsInCart",
        //             JSON.stringify([...prevPromoItems, [thisItem, itemId, promotion.col0]])
        //         );
        //     } else {
        //         window.sessionStorage.setItem(
        //             "promotionItemsInCart",
        //             JSON.stringify([[thisItem, itemId, promotion.col0]])
        //         );
        //     }
        // }
    }

    async function changeTotalQty(value, stockCode, currentPromotionTotal, currentPromotionSC, mass){

        if(specialType === 2){

            if(!currentPromotionSC.includes(stockCode)){
                setCurrentPromotionSC([...currentPromotionSC, stockCode])
                setCurrentPromotionTotal([...currentPromotionTotal, [stockCode, value]]);
                var newCurr = [...currentPromotionTotal, [stockCode, value]];
            }else{
                await Object.keys(currentPromotionTotal).forEach(function (key) {
                    if(currentPromotionTotal[key][0] == stockCode){
                        currentPromotionTotal[key][1] = value
                        setCurrentPromotionTotal([...currentPromotionTotal]);
                        
                    }
                })
                var newCurr = [...currentPromotionTotal];
            }
    
            var total = 0;
            await Object.keys(newCurr).forEach(function (key) {
                total += parseFloat(newCurr[key][1]);
            })

        }else{

            //mass

            if(!currentPromotionSC.includes(stockCode)){
                setCurrentPromotionSC([...currentPromotionSC, stockCode])
                setCurrentPromotionTotal([...currentPromotionTotal, [stockCode, value, mass]]);
                var newCurr = [...currentPromotionTotal, [stockCode, value, mass]];
            }else{
                await Object.keys(currentPromotionTotal).forEach(function (key) {
                    if(currentPromotionTotal[key][0] == stockCode){
                        currentPromotionTotal[key][1] = value
                        setCurrentPromotionTotal([...currentPromotionTotal]);
                        
                    }
                })
                var newCurr = [...currentPromotionTotal];
            }
    
            var total = 0;
            await Object.keys(newCurr).forEach(function (key) {
                total += (parseFloat(newCurr[key][2]) * parseFloat(newCurr[key][1]));
            })

        }

        // setCurrentSelected(total)

        return total;

    }

    async function updateDisabled(total){

        // if(specialType === 2){

            if(specialSize === 1){
                if(total < specialQty){
                    setButtonDisabled(false)
                }else{
                    setButtonDisabled(true)
                }
            }

            if(specialSize === 2){
                if(total === specialQty){
                    setButtonDisabled(false)
                }else{
                    setButtonDisabled(true)
                }
            }

            if(specialSize === 3){
                if(total > specialQty){
                    setButtonDisabled(false)
                }else{
                    setButtonDisabled(true)
                }
            }
        // }
    }

    async function setTotals(totalAmount, itemId) {

        itemId = itemId.trim();

        await axios({
          method: "post",
          url: `${process.env.REACT_APP_API_URL}update-qty-in-cart`,
          headers: { Authorization: `Bearer ${currentUser.token}` },
          data: {
            StockCode: itemId,
            id: currentUser.id,
            qty: totalAmount,
            customer_code: currentUser.customer_code,
          },
        });

        await getProductsSpecial();
    }

    return (
        <div className="fixed z-50 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                    className="fixed inset-0 transition ease-in duration-300"
                    aria-hidden="true"
                >
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                </div>

                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                    &#8203;
                    <ToastContainer />
                </span>
                
                <div 
                    className="
                        inline-block align-middle bg-white rounded-lg px-4 py-5 pt-5 pb-4 
                        overflow-hidden shadow-xl transform transition-all
                    "
                >
                    <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                        <button
                            type="button"
                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                            onClick={() => dispatch(closeModal())}
                        >
                            <span className="sr-only">Close</span>
                            <FontAwesomeIcon className="text-lk_red text-2xl hover:text-lk_red_hover" aria-hidden="true" icon={faTimesCircle}/>
                        </button>
                    </div>
                    <div>
                        <div className="mt-3 text-center sm:mt-16">
                            <h1 className="text-lg sm:text-2xl lg:text-4xl uppercase">
                                {promotion.col1}
                            </h1>
                            <p>{promotion.col17}</p>
                            {promotion.col16 !== "" && promotion.col16 !== undefined && (
                                <><br/><img src={process.env.REACT_APP_URL+"fliers/"+promotion.col16} style={{maxHeight:"500px", margin: "auto"}} /></>
                            )}
                            <div className="mt-2 sm:px-32 mt-12 mb-5">
                                <div className="mt-2 grid grid-cols-1 gap-4">
                                    <div>
                                        <table className="border-collapse border border-slate-400">
                                            <thead>
                                                <tr className="bg-gray-100 flex flex-col flex-no wrap md:table-row w-full">
                                                    {/* {promotion.col7 == 1 || promotion.col13 == 2 ? 
                                                        <th className="border border-slate-300">Select</th> 
                                                    : ""} */}
                                                    {/* <th className="border border-slate-300">Buy Amount</th> */}
                                                    <th className="lg:border border-slate-300">Stock Code</th>
                                                    <th className="lg:border border-slate-300">Description</th>
                                                    <th className="lg:border border-slate-300">Price Per Unit</th>
                                                    <th className="lg:border border-slate-300">Stock Status</th>
                                                    <th className="lg:border border-slate-300">Qty</th>
                                                    <th className="lg:border border-slate-300"></th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {products.map((product, index) => ( 
                                                    <>
                                                        {(promotion.col11).includes(product.StockCode) ? 
                                                            <tr className="bg-white rounded mt-5 flex flex-col flex-no wrap md:table-row">
                                                                {/* {promotion.col7 == 1 || promotion.col13 == 2 ? 
                                                                    <td className="border border-slate-300"> 
                                                                        <input type="checkbox" onClick={(value) => {
                                                                            console.log(value.target.checked); 
                                                                            var total = purchaseTotal; 
                                                                            if(value.target.checked == true){
                                                                                var total = purchaseTotal + 1; 
                                                                            }else{
                                                                                var total = purchaseTotal - 1; 
                                                                            }
                                                                            
                                                                            setPurchaseTotal(total)
                                                                            }}/> 
                                                                    </td> 
                                                                : ""} */}
                                                                {/* <td className="border border-slate-300 px-5 w-56 h-20"> 
                                                                    <input type="number" min='0' name={`qty.${index}`} className="focus:ring-gray-500 focus:border-gray-500 border-gray-300" onChange={(values) => {values.target.value = Math.abs(values.target.value); let newTot = changeTotalQty(values.target.value, product.StockCode, currentPromotionTotal, currentPromotionSC, product.Mass); newTot.then(function(response){updateDisabled(response)});}}/>
                                                                </td> */}
                                                                <td className="lg:border md:border-r border-slate-300 lg:px-5 lg:w-56 lg:h-20 md:px-5 md:w-56 md:h-20 sm:text-2xl"> 
                                                                    {/* {product.StockCode}  */}
                                                                    {product.display !== "" ? product.display : product.StockCode}
                                                                </td>
                                                                <td className="lg:border md:border-r border-slate-300 px-2"> 
                                                                    {product.Description} {product.LongDesc} 
                                                                </td>
                                                                <td className="lg:border md:border-r border-slate-300 px-2"> 
                                                                    <strike className="text-gray-400">R {parseFloat(product.SellingPrice).toFixed(2)}</strike>
                                                                    <br/>
                                                                    R {(parseFloat(product.SellingPrice) - (parseFloat(product.SellingPrice) * parseFloat("0."+(parseFloat(promotion.col6) > 9 ? promotion.col6 : "0"+promotion.col6)))).toFixed(2)}
                                                                </td>
                                                                <td className="lg:border md:border-r border-slate-300 px-2 sm:text-xl"> 
                                                                    {/* {product.InvWarehouse} */}

                                                                    {product.InvWarehouse === "Out of Stock" ?
                                                                        <p className="text-red-500">{product.InvWarehouse}</p> 
                                                                    : <p>{product.InvWarehouse}</p> }

                                                                </td>
                                                                <td className="lg:border md:border-r border-slate-300 px-2"> 
                                                                    <div>
                                                                        {product.InvWarehouse === "Out of Stock" ?
                                                                        ""
                                                                        : 
                                                                        <input type="number" id={"number_"+product.StockCode} min={product.inCart == "yes" ? "1" : "0"} defaultValue = {product.qty} className="shadow-sm text-sm border border_radius_5 w-20" onChange={(values) => {let mintot = product.inCart == "yes" ? 1 : 0; parseFloat(values.target.value) < mintot ? setTotals(mintot, product.StockCode) : setTotals(values.target.value, product.StockCode); if(values.target.value == 0){values.target.value = mintot}else{values.target.value = Math.abs(values.target.value)}}}/>}
                                                                    </div>
                                                                </td>
                                                                <td className="flex">
                                                                    {product.InvWarehouse  === "Out of Stock" ? 
                                                                        <>
                                                                            
                                                                        </> 
                                                                    : 
                                                                        <>
                                                                            <button
                                                                                className="px-4 py-2 lg:mr-5 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase mx-auto flex lg:mt-3 lg:ml-2 md:mt-3 md:ml-2"
                                                                                onClick={() =>
                                                                                    addItemToCart(
                                                                                        product.StockCode,
                                                                                        1,
                                                                                        "number_"+product.StockCode
                                                                                    )
                                                                                }
                                                                            >
                                                                                {/* Add to cart */}
                                                                                {/* + <FontAwesomeIcon icon={faShoppingCart}/> */}
                                                                                <span className="pr-2">+</span> <FontAwesomeIcon icon={faShoppingCart} className="mt-1"/>
                                                                            </button>


                                                                            {product.inCart == "yes" ? (
                                                                                <span className="mt-6 bg-red-500 h-7 px-2 py-1 rounded-full text-white mr-1 min-w-min_red_btn flex justify-center" data-tip={product.qty +" of this item is in your cart"}>
                                                                                {product.qty}
                                                                                <ReactTooltip />
                                                                                </span>
                                                                            ) : (
                                                                                <div className="mt-6 h-6 px-2 py-1 rounded-full text-white"></div>
                                                                            )}
                                                                        </>
                                                                    }
                                                                </td>
                                                            </tr> 
                                                        : ""}
                                                    </>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <br/><br/>
                                {/* <label>Number of Promotions: </label>
                                <input type="number" min='0' className="focus:ring-gray-500 focus:border-gray-500 border-gray-300" value={total} onChange={(value) => setTotal(value.target.value)}/> */}

                                

                                {/* {console.log(specialType)} */}
                                

                                <br/><br/>
                                <div >
                                    {/* <div>
                                        <CustomButton
                                            type="button"
                                            text="Claim Promotion"
                                            className={buttonDisabled == true ? disabledCss : activeCss}
                                            disabled={buttonDisabled}
                                            onClick={() => claimDeal()}
                                        />
                                    </div> */}
                                    <div>
                                        <CustomButton
                                            type="button"
                                            text="Close"
                                            className="lkbutton bg-gray-400 hover:bg-gray-600 w-60 border_radius_10 "
                                            onClick={() => dispatch(closeModal())}
                                        />
                                    </div>
                                </div>
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}