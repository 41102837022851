import React from "react";
// import CatalogueCTA from "../../../app/common/elements/CatalogueCTA";
import CustomHeader from "../../../app/common/elements/CustomHeader";
import ServicesImageCard from "../../../app/common/elements/ServicesImageCard";
import IconCard from "../../../app/common/elements/IconCard";

export default function LogisticsPage() {
    const services = [
        {image: "/images/icons/map_marker.png", text: "A single point of contact", count: "01"},
        {image: "/images/icons/award.png", text: "Full transparency and accountability", count: "02"},
        {image: "/images/icons/check_mark.png", text: "Industry best practices", count: "03"},
        {image: "/images/icons/support.png", text: "A solution orientated team", count: "04"},
        {image: "/images/icons/transport.png", text: "Transport modelling", count: "05"},
        {image: "/images/icons/customers.png", text: "Customer Focus", count: "06"},
        {image: "/images/icons/system.png", text: "Custom designed warehouse management and logistic systems", count: "07"},
    ];
    return (
        <div className="bg-lk_white_backround">
            <CustomHeader heading="Logistics" subHeading="Services" breadcrumbs={[
                {name: "Home", href: '/', count: 1},
                {name: "Services", href: '/services', count: 2},
                {name: "Logistics", href: '/services/logistics', count: 3},
            ]}/>

            <p className="text-lk_grey_one text-2xl sm:text-3xl lg:text-5xl mx-auto my-12 text-center px-4 max-w-xs sm:max-w-2xl lg:max-w-7xl">Lakato is well positioned to offer an outsourced logistical solution to organisations in the Animal Health Industry.</p>
            <p className="mx-auto text-center mb-12 text-lg px-4 max-w-xs sm:max-w-2xl lg:max-w-7xl">Transform your direct costs to variable costs and partner with an industry leader.</p>

            <ServicesImageCard
                image="/images/services/lakato_logistics_header.jpg" imageAlt="Lakato Logistics"
                textOne={"We have a well established and reliable network with proven procedures and systems. Our logistical focus is the customer. Our dispatch and route planning are tailored to offer the customer the best possible service and delivery time. Special direct bulk deliveries can be arranged."}
                textTwo={"Emergency Pharmaceutical deliveries can be arranged should time constraints be applicable."}
            />

            <div className="mt-24 pb-16 lg:px-20 bg-lk_grey_background_dark_d9">
                <p className="text-lg sm:text-2xl lg:text-4xl text-center py-12">We offer:</p>
                <div className="flex flex-wrap justify-center xl:justify-start mx-auto max-w-7xl">
                    {services.map((service) => (
                        <IconCard
                            key={service.text}
                            image={service.image}
                            count={service.count}
                            text={service.text}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}
