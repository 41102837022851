import React, { useState } from "react";
import { closeModal } from "../../app/common/modals/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import CustomButton from "../../app/common/form/CustomButton";
import { useHistory } from "react-router";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";

export default function PriceList(props) {
    const dispatch = useDispatch();
    let history = useHistory();
    const [open] = useState(true);
    const { currentUser } = useSelector((state) => state.auth);

    return (
        <div className="fixed z-50 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                    className="fixed inset-0 transition ease-in duration-300"
                    aria-hidden="true"
                >
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                </div>

                <span className="  align-middle sm:h-screen" aria-hidden="true">
                    &#8203;
                    <ToastContainer />
                </span>

                <div
                    className="inline-block align-middle bg-white rounded-lg px-4 pt-5 pb-4 
                    overflow-hidden shadow-xl transform transition-all 
                    "
                >
                    <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                        <button
                            type="button"
                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                            onClick={() => dispatch(closeModal())}
                        >
                            <span className="sr-only">Close</span>
                            <FontAwesomeIcon
                                className="text-lk_red text-2xl hover:text-lk_red_hover"
                                aria-hidden="true"
                                icon={faTimesCircle}
                            />
                        </button>
                    </div>
                    <div>
                        <div className="mt-3 text-center sm:mt-16">
                            <h1 className="text-xl">Download Price List</h1>
                            <br />
                            <div className="mt-2 sm:px-32 flex space-x-4">
                                <a
                                    className="lkbutton bg-gray-400 hover:bg-gray-600 w-32 sm:w-52 border_radius_10"
                                    href={`${process.env.REACT_APP_URL}price_list/1/${currentUser?.branch}/${currentUser?.customer_code}/0/${currentUser?.id}`}
                                    target="_blank"
                                >
                                    PDF
                                </a>

                                <a
                                    className="lkbutton bg-gray-400 hover:bg-gray-600 w-32 sm:w-52 border_radius_10"
                                    href={`${process.env.REACT_APP_URL}price_list/2/${currentUser?.branch}/${currentUser?.customer_code}/0/${currentUser?.id}`}
                                    target="_blank"
                                >
                                    Excel
                                </a>
                            </div>
                            <br />
                            <br />
                            <CustomButton
                                type="button"
                                text="Close"
                                className="lkbutton bg-lk_red hover:bg-lk_red_hover w-32 sm:w-96 border_radius_10"
                                onClick={() => dispatch(closeModal())}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
