import React from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../app/common/modals/modalReducer";

export default function ProductCard({ image, text, href }) {
    const { currentUser } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    return (
        <Fade left>
            <div className="bg-white border_radius_10 mx-4 my-4 px-10 pt-10 pb-12 w-72 sm:w-80 lg:w-96 h-96 sm:h-card_432px shadow-card_border relative">
                <img src={image} alt={text} />
                <p className="text-lg sm:text-2xl lg:text-4xl py-10">{text}</p>
                {currentUser !== null ? (
                    <>
                        <Link className={"bg-lk_grey_one text-white hover:bg-lk_grey_two uppercase border_radius_4 py-3 px-12 text-base font-semibold absolute bottom-10"} as="a" to={href}>
                            View All
                        </Link>
                    </>
                ) : (
                    <>
                        <button
                            className="bg-lk_grey_one text-white hover:bg-lk_grey_two uppercase border_radius_4 py-3 px-12 text-base font-semibold absolute bottom-10"
                            onClick={() => dispatch(openModal({ modalType: "ModalLogin" }))}
                        >
                            View All
                        </button>
                    </>
                )}
            </div>
        </Fade>
    );
}
