import React, { Fragment, useEffect, useState } from 'react'
import { closeModal } from "../../app/common/modals/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Transition } from '@headlessui/react';
import { faExclamationCircle, faShoppingCart, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { LoginForm } from '../forms/LoginForm';
import CustomButton from '../../app/common/form/CustomButton';
import axios from 'axios';
import { faCaretDown, faCaretUp, faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/pro-regular-svg-icons";
import { useHistory } from 'react-router';
import { useTable, usePagination, useSortBy, useResizeColumns } from "react-table";
import ReactTooltip from 'react-tooltip';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from "react-toastify";

export default function Alternative(props) {
    const dispatch = useDispatch();
    let history = useHistory();
    const [open] = useState(true);
    const [products, setProducts] = useState([])
    const { currentUser } = useSelector((state) => state.auth);
    const allowedSups = currentUser.supplier_stock != null ? currentUser.supplier_stock.split(",").map(function (item) { return item.trim(); }) : currentUser.supplier_stock;

    let stockCode = props.stockCode;
    let AltStocCode = props.altStock;
    // console.log(props)

    useEffect(() => {
        getProducts();
    }, [])

    async function getProducts() {
        let favouritesResults = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}favourites`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                userId: currentUser.id,
            },
        });

        let favData = favouritesResults.data[0];
        //console.log('favData------------------', favData);
        let favIds = [];

        await Object.keys(favData).forEach(function (key) {
            favIds.push(favData[key].favourite_product_id);
        });

        let alternatives = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}alternatives`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                stockCode: props.stockCode,
                priceCode: currentUser.isCustomer === 1 && currentUser.customer_code !== null ? currentUser.customer_code : currentUser.customer_code === null || currentUser.customer_code.split(",").length > 1 ? "None" : currentUser.customer_code,
                branch: currentUser.branch,
                userId: currentUser.id,
                allowed_supplier: currentUser.allowed_supplier,
            },
        });

        //console.log('alternatives.data ', alternatives.data[0])

        let prodData = alternatives.data[0];
        let cartData = alternatives.data[1];
        let allproducts = [];
        let totalItems = JSON.parse(window.sessionStorage.getItem("totalItemsInBasket"));

        await Object.keys(prodData).forEach(async function (key) {
            let newStockCode = prodData[key].StockCode;
            newStockCode = newStockCode.replace(" ", "");

            if (newStockCode) {
                let curfav = false;

                if (favIds.includes((prodData[key].StockCode).trim())) {
                    curfav = true;
                }

                let total = "0";
                let inCart = "no";
                // if(totalItems !== null){
                //     Object.keys(totalItems).forEach(function (index) {
                //         if(totalItems[index][1].trim() == prodData[key].StockCode.trim()){
                //             total = totalItems[index][0];
                //         }
                //     });
                // }
                // cartData
                await Object.keys(cartData).forEach(function (index) {
                    if (prodData[key].StockCode === cartData[index].stock_code) {
                        total = cartData[index].qty
                        inCart = "yes";
                    }
                })
                //console.log('prodData[key] ', prodData[key]);


                if (prodData[key].InvWarehouse == "Stock available") {
                    allproducts.push({
                        col0: prodData[key].col0,
                        col1: prodData[key].col1,
                        col2: prodData[key].StockCode,
                        col3: prodData[key].Description,
                        col4: prodData[key].InvWarehouse,
                        col5: prodData[key].SellingPrice
                            ? "R " + parseFloat(prodData[key].SellingPrice).toFixed(2)
                            : "Free",
                        col6: total,
                        col7: curfav,
                        col8: prodData[key].LongDesc,
                        col9: prodData[key].AlternateUom,
                        col10: inCart,
                        col25: prodData[key].InvwarehouseQty,
                        col26: prodData[key].Supplier,
                        img: prodData[key].img,
                    });
                }
            }
        });

        // console.log(allproducts)

        const someTimeoutAction2 = () => {
            return new Promise((resolve) => {
                setTimeout(function () {
                    resolve(allproducts);
                }, 600);
            });
        };

        const prods2 = await someTimeoutAction2();

        console.log('prods2 ', prods2);
        setProducts(prods2)
    }

    async function addFavourite(rowId) {
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}add-new-favourite`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                rowId: rowId,
                userId: currentUser.id,
            },
        });

        getProducts();
    }

    async function removeFavourite(rowId) {
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}remove-favourite`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                rowId: rowId,
                userId: currentUser.id,
            },
        });

        getProducts();
    }
    async function addItemToCart(itemId, qty) {
        itemId = itemId.trim();

        let cartItem = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}add-items-to-cart`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                StockCode: itemId,
                id: currentUser.id,
                qty: qty,
                auto: 0,
                customer_code: currentUser.customer_code,
            },
        });

        if (cartItem.data == 1) {
            let CurrentCartAmount = window.sessionStorage.getItem("itemsInBasket")
            let NewAmount = parseFloat(CurrentCartAmount) + 1
            window.sessionStorage.setItem("itemsInBasket", NewAmount);
        }

        toast.success("Item Added to Cart", { position: "bottom-right", autoClose: 5000, closeOnClick: true, });

        getProducts();

        //   itemId = itemId.trim();
        // let prevItemClicked = JSON.parse(
        //     window.sessionStorage.getItem("itemsClicked")
        // );
        // if (prevItemClicked !== null) {
        //     window.sessionStorage.setItem(
        //         "itemsClicked",
        //         JSON.stringify([...prevItemClicked, itemId])
        //     );
        // } else {
        //     window.sessionStorage.setItem(
        //         "itemsClicked",
        //         JSON.stringify(itemId)
        //     );
        // }

        // let prevItemIds = JSON.parse(
        //     window.sessionStorage.getItem("itemsIdsInBasket")
        // );

        // if (!prevItemIds.includes(itemId)) {
        //     window.sessionStorage.setItem(
        //         "itemsIdsInBasket",
        //         JSON.stringify([...prevItemIds, itemId])
        //     );

        //     let CurrentCartAmount =
        //         window.sessionStorage.getItem("itemsInBasket");
        //     let NewAmount = parseFloat(CurrentCartAmount) + 1;
        //     window.sessionStorage.setItem("itemsInBasket", NewAmount);

        // } else {
        //     let totalItemsClicked = JSON.parse(
        //         window.sessionStorage.getItem("itemsClicked")
        //     );
        //     let thisItem = 0;

        //     await Object.keys(totalItemsClicked).forEach(function (key) {
        //         if (totalItemsClicked[key] === itemId) {
        //             thisItem += 1;
        //         }
        //     });

        //     let prevItemTotals = JSON.parse(
        //         window.sessionStorage.getItem("totalItemsInBasket")
        //     );
        //     if (prevItemTotals !== null) {
        //         window.sessionStorage.setItem(
        //             "totalItemsInBasket",
        //             JSON.stringify([...prevItemTotals, [thisItem, itemId]])
        //         );
        //     } else {
        //         window.sessionStorage.setItem(
        //             "totalItemsInBasket",
        //             JSON.stringify([[thisItem, itemId]])
        //         );
        //     }
        // }
    }

    async function setTotals(totalAmount, itemId) {

        itemId = itemId.trim();

        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}update-qty-in-cart`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                StockCode: itemId,
                id: currentUser.id,
                qty: totalAmount,
                customer_code: currentUser.customer_code,
            },
        });
        getProducts();

        // let prevItemTotals = JSON.parse(
        //     window.sessionStorage.getItem("totalItemsInBasket")
        // );
        // let found = false;

        // Object.keys(prevItemTotals).forEach(function (key) {
        //     if(prevItemTotals[key][1].trim() === itemId.trim()){
        //         found = true;
        //         prevItemTotals[key][0] = totalAmount
        //     }
        // })

        // if (prevItemTotals !== null) {
        //     if(found === false){
        //         window.sessionStorage.setItem(
        //             "totalItemsInBasket",
        //             JSON.stringify([...prevItemTotals, [totalAmount, itemId]])
        //         );
        //     }else{
        //         window.sessionStorage.setItem(
        //             "totalItemsInBasket",
        //             JSON.stringify([...prevItemTotals])
        //         );
        //     }
        // }else{
        //     window.sessionStorage.setItem(
        //         "totalItemsInBasket",
        //         JSON.stringify([[totalAmount, itemId]])
        //     );
        // }

    }

    // console.log(products)

    const data = React.useMemo(() => products, [products]);

    const columns = React.useMemo(
        () => [
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "left"
                        }}
                    >Image</div>),
                accessor: "col1",
                Cell: (rowInfo) => (
                    <>
                        {rowInfo.row.original.img !== "" ? (
                        <img src={process.env.REACT_APP_URL + "/media/" + rowInfo.row.original.img} width={150} alt="Product" className="mx-auto px-5 py-5" />
                        ):(
                            <img
                                src={process.env.REACT_APP_URL + "/images/placeholder-image.png"}
                                width={150}
                                alt="Product"
                                className="mx-auto px-5 py-5"
                            />
                        )}
                    </>
                ),
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "left"
                        }}
                        id="StockCode"
                        className="cursor-pointer"
                    >Code</div>),
                accessor: "col2",
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "left"
                        }}
                        id="Description"
                        className="cursor-pointer"
                    >Description</div>),
                accessor: "col3",
                Cell: (rowInfo) => {
                    return (
                        <>
                            <p>{rowInfo.row.original.col3}</p>
                            <p>{rowInfo.row.original.col8}</p>
                        </>
                    );
                },
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "left"
                        }}
                        id="QtyOnHand"
                        className="cursor-pointer"
                    >Stock Status</div>),
                accessor: "col4",
                Cell: (rowInfo) => {
                    return (
                        <>
                            {rowInfo.row.original.col4 === "Out of Stock" ?
                                <p className="text-red-500">{rowInfo.row.original.col4}</p>
                                : <p>{rowInfo.row.original.col4} {currentUser.see_stock === 1 && currentUser.supplier_stock !== null && allowedSups.includes(rowInfo.row.original.col26) ? <>{" ( "}{rowInfo.row.original.col25}{" )"}</> : ""}</p>}

                        </>
                    );
                },

            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "left"
                        }}
                        id="price"
                        className="cursor-pointer"
                    >Price Excl. VAT</div>),
                accessor: "col5",
                Cell: (rowInfo) => {
                    return (
                        <>
                            <p>{rowInfo.row.original.col5}

                                {rowInfo.row.original.col9 == "SD" ?
                                    <>
                                        <span className="text-green-500 ml-2" data-tip="Settlement Discount if Applicable"><FontAwesomeIcon icon={faExclamationCircle} /></span> <ReactTooltip />

                                    </>
                                    : ""}
                            </p>



                        </>
                    );
                },
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "left"
                        }}
                    >QTY</div>),
                accessor: "col6",
                Cell: (rowInfo) => {
                    return (
                        // <Select
                        //     options={[
                        //         { value: "01", label: "01" },
                        //         { value: "02", label: "02" },
                        //         { value: "03", label: "03" },
                        //     ]}
                        //     defaultValue={{
                        //         label: rowInfo.row.original.col6,
                        //         value: rowInfo.row.original.col6,
                        //     }}
                        //     isSearchable={false}
                        //     onChange={(values) =>
                        //         setTotals(
                        //             values.value,
                        //             rowInfo.row.original.col2
                        //         )
                        //     }
                        // />
                        <input type="number" min="0" defaultValue={rowInfo.row.original.col6} className="shadow-sm text-sm border border_radius_5 w-20" onChange={(values) => { parseFloat(values.target.value) < 0 ? setTotals(0, rowInfo.row.original.col2) : setTotals(values.target.value, rowInfo.row.original.col2); values.target.value = Math.abs(values.target.value) }} />
                    );
                },
            },
            {
                Header: "",
                accessor: "col7",
                Cell: (rowInfo) => {
                    return (
                        <div className="flex">
                            {rowInfo.row.original.col7 === true ? (
                                <FontAwesomeIcon
                                    icon={solidHeart}
                                    className="absolute top-3 right-3 text-xl text-gray-500 cursor-pointer mr-1"
                                    onClick={() =>
                                        removeFavourite(
                                            rowInfo.row.original.col2
                                        )
                                    }
                                />
                            ) : (
                                <FontAwesomeIcon
                                    icon={faHeart}
                                    className="absolute top-3 right-3 text-xl text-gray-500 cursor-pointer mr-1"
                                    onClick={() =>
                                        addFavourite(rowInfo.row.original.col2)
                                    }
                                />
                            )}



                            {/* {JSON.parse(window.sessionStorage.getItem("itemsIdsInBasket")) !== null && JSON.parse(
                                        window.sessionStorage.getItem(
                                            "itemsIdsInBasket"
                                        )
                                    ).includes(
                                        (rowInfo.row.original.col2).trim()
                                    ) ? (
                                        <>
                                            <button
                                                className="px-4 py-2 mr-8 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase mx-auto"
                                                onClick={() =>
                                                    history.push("/view-cart")
                                                }
                                            >
                                                View Cart
                                            </button>
                                        </>
                                    ) : (
                                        <> */}
                            {/* {rowInfo.row.original.col4 === "Out of Stock" ? 
                                            <>
                                                
                                            </> 
                                        :  */}
                            <>
                                <button
                                    className="px-4 py-2 mr-8 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase mx-auto flex"
                                    onClick={() =>
                                        addItemToCart(
                                            rowInfo.row.original.col2,
                                            rowInfo.row.original.col6
                                        )
                                    }
                                >
                                    {/* Add to cart */}
                                    {/* + <FontAwesomeIcon icon={faShoppingCart}/> */}
                                    <span className="pr-2">+</span> <FontAwesomeIcon icon={faShoppingCart} className="mt-1" />
                                </button>
                            </>
                            {/* } */}

                            {/* </>
                                    )} */}

                            {rowInfo.row.original.col10 == "yes" ? (
                                <span className="mt-7 bg-red-500 h-6 px-2 py-1 rounded-full text-white mr-1 min-w-min_red_btn flex justify-center" data-tip={rowInfo.row.original.col6 + " of this item is in your cart"}>
                                    {rowInfo.row.original.col6}
                                    <ReactTooltip />
                                </span>
                            ) : (
                                <div className="mt-6 h-6 px-2 py-1 rounded-full text-white"></div>
                            )}


                        </div>
                    );
                },
            },
        ],
        []
    );

    const tableInstance = useTable({ columns, data }, useSortBy, usePagination, useResizeColumns);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        prepareRow,
        pageIndex,
        pageOptions,
        gotoPage,
        pageCount,
        pageSize,
        setPageSize,
    } = tableInstance;

    return (

        <div className="fixed z-50 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                    className="fixed inset-0 transition ease-in duration-300"
                    aria-hidden="true"
                >
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                </div>

                <span className="  align-middle sm:h-screen" aria-hidden="true">
                    &#8203;
                    <ToastContainer />
                </span>

                <div
                    className="inline-block align-middle bg-white rounded-lg px-4 pt-5 pb-4 
                    overflow-hidden shadow-xl transform transition-all 
                    "
                >
                    <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                        <button
                            type="button"
                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                            onClick={() => dispatch(closeModal())}
                        >
                            <span className="sr-only">Close</span>
                            <FontAwesomeIcon className="text-lk_red text-2xl hover:text-lk_red_hover" aria-hidden="true" icon={faTimesCircle} />
                        </button>
                    </div>
                    <div>
                        <div className="mt-3 text-center sm:mt-16">
                            <h1 className="text-2xl sm:text-3xl lg:text-5xl">
                                Alternatives Available
                            </h1>
                            <br />
                            <div className="mt-2 sm:px-32">
                                <p>Your alternative options are:</p>
                                <br />
                                <table
                                    {...getTableProps()}
                                    className="mb-10 w-full"
                                    style={{
                                        borderCollapse: "separate",
                                        borderSpacing: "0 1em",
                                    }}
                                    responsive
                                >
                                    <thead>
                                        {headerGroups.map((headerGroup) => (
                                            <tr
                                                {...headerGroup.getHeaderGroupProps()}
                                                className="bg-gray-100 flex flex-col flex-no wrap md:table-row w-full"
                                            >
                                                {headerGroup.headers.map((column) => (
                                                    <th
                                                        {...column.getHeaderProps()}
                                                        className="md:px-2 md:py-5 lg:px-2 lg:py-5 font-light"
                                                    >
                                                        {column.render("Header")}
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {page.map((row) => {
                                            prepareRow(row);
                                            return (
                                                <tr
                                                    {...row.getRowProps()}
                                                    className="bg-white rounded mt-5 flex flex-col flex-no wrap md:table-row border"
                                                >
                                                    {row.cells.map((cell) => {
                                                        console.log('cell------------------', cell);
                                                        return (
                                                            <td
                                                                {...cell.getCellProps()}
                                                                style={{
                                                                    // borderRight:
                                                                    // "1px solid #EFEFEF",
                                                                    textAlign: "left",
                                                                }}
                                                                className="mx-auto text-center relative px-2 py-2 text-sm lg:border md:border-r"
                                                            >
                                                                {cell.render("Cell").props.cell
                                                                    .column.id === "col2" ||
                                                                    cell.render("Cell").props.cell
                                                                        .column.id === "col4" ? (
                                                                    <span className="text-gray-500" style={{ textAlign: "left" }}>
                                                                        {cell.render("Cell")}
                                                                    </span>
                                                                ) : (
                                                                    cell.render("Cell")
                                                                )}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <br /><br />
                            <CustomButton
                                type="button"
                                text="Close"
                                className="lkbutton bg-lk_red hover:bg-lk_red_hover w-32 sm:w-96 border_radius_10"
                                onClick={() => dispatch(closeModal())}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}