import { useState, useEffect, Fragment, useRef } from "react";
import axios from "axios";
import { Dialog, Transition } from "@headlessui/react";
import { useSelector } from "react-redux";
import Select from "react-select";
import moment from "moment";

export function AdminProducts() {
    const { currentUser } = useSelector((state) => state.auth);
    const [products, setProducts] = useState([]);
    const [tags, setTags] = useState([]);
    const [open, setOpen] = useState(false);
    const [openTag, setOpenTag] = useState(false);
    const cancelButtonRef = useRef(null);
    const [data, setData] = useState({});
    const [tagData, setTagData] = useState([]);
    const [selectedTag, setSelectedTag] = useState([]);
    const [load_data, setLoadData] = useState(true);
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [perPage, setPerPage] = useState(10); // Items per page

    useEffect(() => {
        if (load_data == true) {
            axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}get_products_admin`,
                headers: { Authorization: `Bearer ${currentUser.token}` },
                data: {
                    userId: currentUser.id,
                    search: search,
                    per_page: perPage,
                    page: currentPage,
                },
            }).then((res) => {
                setProducts(res.data.products.data); // Adjust based on your response structure
                setTags(res.data.tags);
                setTotalPages(res.data.products.last_page); // Get total pages from response

                if (data?.id > 0) {
                    setData(res.data.products.data?.find((p) => p.id === data.id)); // Set the product data based on the selected product
                }
            });

            setLoadData(false);
        }
    }, [load_data]);

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setCurrentPage(1); // Reset to first page on search
        setLoadData(true);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setLoadData(true);
    };

    const openPopup = (productCategory, type) => {
        if (!productCategory.id) {
            setData({
                id: 0,
                StockCode: "",
                RapidTradeDesc: "",
                RapidTradeSearch1: "",
                RapidTradeSearch2: "",
                RapidTradeSearch3: "",
                RapidTradeSearch4: "",
                tags: [],
            });
        } else {
            setData(productCategory);
        }

        setOpen(true);
    };

    const openTagPopup = (product) => {
        setTagData(product);
        setOpenTag(true);
    };

    const handleOnChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const submit = (e) => {
        e.preventDefault();
        console.log(data);

        //axios post to update product category
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}save_product_admin`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: data,
        }).then((res) => {
            console.log(res);
            setOpen(false);
            setLoadData(true);
        });
    };

    const deleteProduct = (id) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("Are you sure you wish to delete this item?")) {
            axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}delete_product_admin`,
                headers: { Authorization: `Bearer ${currentUser.token}` },
                data: { id: id },
            }).then((res) => {
                setOpen(false);
                setLoadData(true);
            });
        }
    };

    const addTag = (tagId, productId) => {
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}product_link_tag_admin`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: { tagId: tagId, productId: productId },
        }).then((res) => {
            setLoadData(true);
        });
    };

    const deleteTag = (id, productId) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("Are you sure you wish to delete this item?")) {
            axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}product_unlink_tag_admin`,
                headers: { Authorization: `Bearer ${currentUser.token}` },
                data: { id: id, productId: productId },
            }).then((res) => {
                setLoadData(true);
            });
        }
    };

    const handleSyncPrices = () => {
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}sync_product_price`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                id: data.id, // Pass the product ID to the backend
            },
        })
            .then((res) => {
                console.log(res.data.message); // Log success message
                setLoadData(true); // Optionally refresh the product data
            })
            .catch((error) => {
                console.error("Error syncing prices:", error);
            });
    };

    return (
        <div className="bg-white">
            <div className="my-8 space-y-6 px-4 sm md:max-w-2xl lg:max-w-4xl xl:max-w-5xl m-auto">
                <div className="flex justify-between items-center">
                    <h1>Products</h1>

                    <input type="text" placeholder="Search Products" value={search} onChange={handleSearchChange} className="border rounded p-2" />

                    <button
                        className="px-2 py-2 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase"
                        onClick={() => openPopup([])}
                    >
                        Add
                    </button>
                </div>

                <div className="border-b border-gray-200 w-full">
                    <div className="grid grid-cols-6">
                        <div className="flex items-center">Product</div>
                        <div className="flex items-center">Stock Code</div>
                        <div className="flex items-center">Description</div>
                        <div className="flex items-center">RapidTrade</div>
                        <div className="flex items-center">Tags</div>
                        <div className="flex items-center">Actions</div>
                    </div>
                </div>

                {products?.map((product) => (
                    <div key={product.id}>
                        <div className="border-b border-gray-200 w-full">
                            <div className="grid grid-cols-6">
                                <div className="flex items-center">
                                    <img src={process.env.REACT_APP_URL + "/images/placeholder-image.png"} alt="product_image" className="w-10 h-10 rounded-full" />
                                </div>

                                <div className="flex items-center">
                                    <p className="text-lg">{product.StockCode}</p>
                                </div>

                                <div className="flex items-center">
                                    <p className="text-lg">{product.Description}</p>
                                </div>

                                <div className="flex items-center">
                                    <p className="text-lg">
                                        {product.RapidTradeDesc}
                                        {product.RapidTradeSearch1 && " " + product.RapidTradeSearch1}
                                        {product.RapidTradeSearch2 && " " + product.RapidTradeSearch2}
                                        {product.RapidTradeSearch3 && " " + product.RapidTradeSearch3}
                                        {product.RapidTradeSearch4 && " " + product.RapidTradeSearch4}
                                    </p>
                                </div>

                                <div className="flex items-center">
                                    <p className="text-lg">
                                        {product.tags?.map((tag, index) => (
                                            <span key={tag.id}>
                                                {index > 0 && ", "}
                                                {tag.name}
                                            </span>
                                        ))}
                                    </p>
                                </div>

                                <div className="space-x-2">
                                    <button
                                        className="px-2 py-2 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase"
                                        onClick={() => openPopup(product)}
                                    >
                                        Edit
                                    </button>

                                    <button
                                        className="px-2 py-2 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase"
                                        onClick={() => openTagPopup(product)}
                                    >
                                        Tags
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}

                {/* Pagination Controls */}
                <div className="flex justify-between mt-4">
                    <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50">
                        Previous
                    </button>
                    <span>
                        Page {currentPage} of {totalPages}
                    </span>
                    <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50">
                        Next
                    </button>
                </div>
            </div>

            <Transition.Root show={open == true} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => {}}>
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                                    <form onSubmit={submit}>
                                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6  py-8">
                                            <div className="sm:col-span-3">
                                                <label htmlFor="name">Stock Code</label>
                                                <input
                                                    id="StockCode"
                                                    type="text"
                                                    name="StockCode"
                                                    value={data.StockCode}
                                                    className="mt-1 block w-full"
                                                    onChange={handleOnChange}
                                                    placeholder="Stock Code"
                                                    required
                                                />
                                            </div>

                                            <div className="sm:col-span-3">
                                                <label htmlFor="name">Rapid Trade Description</label>
                                                <input
                                                    id="RapidTradeDesc"
                                                    type="text"
                                                    name="RapidTradeDesc"
                                                    value={data.RapidTradeDesc}
                                                    className="mt-1 block w-full"
                                                    onChange={handleOnChange}
                                                    placeholder="Rapid Trade Description"
                                                />
                                            </div>

                                            <div className="sm:col-span-3">
                                                <label htmlFor="name">Rapid Trade Search 1</label>
                                                <input
                                                    id="RapidTradeSearch1"
                                                    type="text"
                                                    name="RapidTradeSearch1"
                                                    value={data.RapidTradeSearch1}
                                                    className="mt-1 block w-full"
                                                    onChange={handleOnChange}
                                                    placeholder="Rapid Trade Search 1"
                                                />
                                            </div>

                                            <div className="sm:col-span-3">
                                                <label htmlFor="name">Rapid Trade Search 2</label>
                                                <input
                                                    id="RapidTradeSearch2"
                                                    type="text"
                                                    name="RapidTradeSearch2"
                                                    value={data.RapidTradeSearch2}
                                                    className="mt-1 block w-full"
                                                    onChange={handleOnChange}
                                                    placeholder="Rapid Trade Search 2"
                                                />
                                            </div>

                                            <div className="sm:col-span-3">
                                                <label htmlFor="name">Rapid Trade Search 3</label>
                                                <input
                                                    id="RapidTradeSearch3"
                                                    type="text"
                                                    name="RapidTradeSearch3"
                                                    value={data.RapidTradeSearch3}
                                                    className="mt-1 block w-full"
                                                    onChange={handleOnChange}
                                                    placeholder="Rapid Trade Search 3"
                                                />
                                            </div>

                                            <div className="sm:col-span-3">
                                                <label htmlFor="name">Rapid Trade Search 4</label>
                                                <input
                                                    id="RapidTradeSearch4"
                                                    type="text"
                                                    name="RapidTradeSearch4"
                                                    value={data.RapidTradeSearch4}
                                                    className="mt-1 block w-full"
                                                    onChange={handleOnChange}
                                                    placeholder="Rapid Trade Search 4"
                                                />
                                            </div>
                                        </div>

                                        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-3 sm:gap-3">
                                            <button type="submit" className="lkbutton rounded-xl bg-lk_red hover:bg-lk_red_hover text-sm sm:text-md xl:text-lg">
                                                SAVE
                                            </button>

                                            {data.id > 0 && (
                                                <button
                                                    type="button"
                                                    className="inline-flex items-center justify-center w-full px-4 py-3 bg-red-400 text-body rounded-xl uppercase disabled:opacity-25 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 hover:bg-accent"
                                                    onClick={() => deleteProduct(data.id)}
                                                >
                                                    DELETE
                                                </button>
                                            )}

                                            <button
                                                type="button"
                                                className="inline-flex items-center justify-center w-full px-4 py-3 bg-gray-400 text-body rounded-xl uppercase disabled:opacity-25 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 hover:bg-accent"
                                                onClick={() => setOpen(false)}
                                                ref={cancelButtonRef}
                                            >
                                                CANCEL
                                            </button>
                                        </div>

                                        <div className="mt-5 sm:mt-6">
                                            <p className="text-lg font-bold text-center">Prices</p>
                                            <div className="grid grid-cols-5">
                                                <div className="flex items-center">Price Code</div>
                                                <div className="flex items-center">Selling Price</div>
                                                <div className="flex items-center">Price Basis</div>
                                                <div className="flex items-center">Commission Code</div>
                                                <div className="flex items-center">Last Updated</div>
                                            </div>

                                            {data?.prices?.map((price) => (
                                                <div key={price.id} className="grid grid-cols-5">
                                                    <div className="flex items-center">{price.PriceCode}</div>
                                                    <div className="flex items-center">{price.SellingPrice}</div>
                                                    <div className="flex items-center">{price.PriceBasis}</div>
                                                    <div className="flex items-center">{price.CommissionCode}</div>
                                                    <div className="flex items-center">{moment(price.updated_at).format("YYYY-MM-DD HH:mm:ss")}</div>
                                                </div>
                                            ))}

                                            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-3 sm:gap-3">
                                                <button type="button" className="lkbutton rounded-xl bg-lk_red hover:bg-lk_red_hover text-sm sm:text-md xl:text-lg" onClick={handleSyncPrices}>
                                                    Sync Prices
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            <Transition.Root show={openTag == true} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => {}}>
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                                    <div className="mb-4 text-center text-lg font-bold">Product: {tagData.name}</div>

                                    <div className="grid grid-cols-3 gap-4">
                                        <div className="sm:col-span-2">Item</div>
                                        <div className="text-center">Actions</div>

                                        <div className="sm:col-span-2">
                                            <Select defaultValue="" options={tags} onChange={(selectedOptions) => setSelectedTag(selectedOptions.value)} name="tag" classNamePrefix="select" />
                                        </div>

                                        <div className="text-center">
                                            <button
                                                className="px-2 py-2 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase"
                                                type="button"
                                                onClick={() => addTag(selectedTag, tagData.id)}
                                            >
                                                Add
                                            </button>
                                        </div>

                                        {tagData?.tags?.map((tag) => (
                                            <>
                                                <div className="sm:col-span-2">
                                                    <p>{tag.name}</p>
                                                </div>

                                                <div className="text-center">
                                                    <button
                                                        className="px-2 py-2 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase"
                                                        onClick={() => deleteTag(tag.id, tagData.id)}
                                                    >
                                                        Delete
                                                    </button>
                                                </div>
                                            </>
                                        ))}
                                    </div>

                                    <div className="mt-5 sm:mt-6">
                                        <button
                                            type="button"
                                            className="inline-flex items-center justify-center w-full px-4 py-3 bg-gray-400 text-body rounded-xl uppercase disabled:opacity-25 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 hover:bg-accent"
                                            onClick={() => setOpenTag(false)}
                                            ref={cancelButtonRef}
                                        >
                                            CLOSE
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    );
}
