import { combineReducers } from "redux";
import authReducer from "../../features/auth/authReducer";
import asyncReducer from "../async/asyncReducer";
import modalReducer from "../common/modals/modalReducer";
import { cartReducer } from "../../features/nav/NavBar/cartReducer";
import { connectRouter } from "connected-react-router";

const rootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        modals: modalReducer,
        auth: authReducer,
        async: asyncReducer,
        cart: cartReducer,
    });

export default rootReducer;
