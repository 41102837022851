import { Formik, Form, FieldArray, Field } from "formik";
import axios from "axios";
import { useHistory } from "react-router-dom";
import * as Yup from 'yup';
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import CustomButton from "../../../../app/common/form/CustomButton";
import CustomText from "../../../../app/common/form/CustomText";
import AdminSearchForm from "../../../forms/AdminSearchForm";
import AdminNav from "../../../nav/AdminNav";
import Select from 'react-select'
import LoadingComponent from "../../../../app/layout/LoadingComponent";

export function EditPromotion(props) {
    let history = useHistory();
    const { currentUser } = useSelector((state) => state.auth);

    let promotionID = props.match.params.promotionID

    const [cartTotal, setCartTotal] = useState([])
    const [specialDetails, setSpecialDetails] = useState([])
    const [selectedUserType, setSelectedUserType] = useState("Customer")
    const [typeOfDiscount, setTypeOfDiscount] = useState("")
    const [limitOnPromotion, setLimitOnPromotion] = useState("")
    const [promotionItems, setPromotionItems] = useState([])
    const [freeItems, setFreeItems] = useState([])
    const [rulesData, setRulesData] = useState([])
    const [custClasses, setCustClasses] = useState([])
    const [defaultCustClasses, setDefaultCustClasses] = useState([])

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setCartTotal(parseFloat(window.sessionStorage.getItem("itemsInBasket")));
        getSpecial();
    }, []);

    let custClassesWithSelectAll = [
        { label: "Select All", value: "*" },
        ...custClasses,
    ];

    async function getSpecial() {
        let custClasses = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}customer-classes`,
            headers: {
                Authorization: `Bearer ${currentUser.token}`
            },
        });

        let allClasses = custClasses.data;

        setCustClasses(allClasses)

        let activeSpecial = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}special-details`,
            headers: {
                Authorization: `Bearer ${currentUser.token}`
            },
            data: { promotionID: promotionID },
        });

        setSpecialDetails(activeSpecial.data)
        setTypeOfDiscount(activeSpecial.data.discount_type);
        setLimitOnPromotion(activeSpecial.data.limit_on_order);

        let allowed = activeSpecial.data.classes;
        let defaultShowing = [];


        if (allowed != null) {
            allClasses.forEach(function (a, i) {
                if (allowed.includes(a.value)) {
                    defaultShowing.push(allClasses[i])
                }
            })
        }
        setDefaultCustClasses(defaultShowing)

        let specialData = activeSpecial.data.items;
        let specialRules = activeSpecial.data.rules;
        let products = [];
        let free = [];
        let rules = [];

        console.log(activeSpecial.data)

        if (specialData) {
            await Object.keys(specialData).forEach(function (key, index) {
                if (specialData[key].product_type == '1') {
                    products.push(specialData[key].product_stock_code)
                }
                if (specialData[key].product_type == '2') {
                    free.push(specialData[key].product_stock_code)
                }
            });

            await Object.keys(specialRules).forEach(function (key, index) {
                rules.push({ "ruleType": specialRules[key].rule_type, "ruleSize": specialRules[key].rule_size, "ruleQuantity": specialRules[key].rule_qty })
            });
        }

        setPromotionItems(products);
        setFreeItems(free);
        setRulesData(rules);
        setLoading(false)
    }

    if (promotionID !== '0') {
        var initialValues = {
            promotion_name: specialDetails.promotion_name,
            from_date: specialDetails.from_date,
            to_date: specialDetails.to_date,
            special_type: specialDetails.special_type,
            discount_type: specialDetails.discount_type,
            buy_amount: specialDetails.req_buy_amount,
            free_amount: specialDetails.free_amount,
            free_product_type: specialDetails.free_product_type,
            special_on: specialDetails.special_on,
            discount_percentage: specialDetails.discount_percentage,
            limit_on_order: specialDetails.limit_on_order,
            limit_per_day: specialDetails.limit_per_day,
            reqProducts: promotionItems,
            freeProducts: freeItems,
            rules: rulesData,
            promo_desc: specialDetails.promo_desc,
            imgDisplay: specialDetails.file_url,
            // OT_class: specialDetails.OT_class,
            // W1_class: specialDetails.W1_class,
            // SS_class: specialDetails.SS_class,
            // VS_class: specialDetails.VS_class,
            // VN_class: specialDetails.VN_class,
            // SN_class: specialDetails.SN_class,
            // VP_class: specialDetails.VP_class,
            // PS_class: specialDetails.PS_class,
            // EX_class: specialDetails.EX_class,
            // NB_class: specialDetails.NB_class,
            accept_terms: specialDetails.accept_terms,

        };

    } else {
        var initialValues = {
            promotion_name: '',
            buy_amount: '',
            from_date: "",
            to_date: '',
        };
    }

    let spcialTypes = [
        { value: "1", label: "Combination of Different Products" },
        { value: "2", label: "Multiple of Same Product" },
        { value: "3", label: "Promotion on various Single Products" }
    ];

    let discountTypes = [
        { value: "1", label: "Percentage Discount" },
        { value: "2", label: "Free Products" }
    ];

    let freeProductTypes = [
        { value: "1", label: "Same Product" },
        { value: "2", label: "Different Product" }
    ];

    let specialOnTypes = [
        { value: "1", label: "Single Product" },
        { value: "2", label: "Group of Products" }
    ];

    let limitTypes = [
        { value: "1", label: "Limit to Initial Order" },
        { value: "2", label: "Limit to Amount Per Day" },
        { value: "3", label: "No Limit" }
    ];

    if (loading === true) {
        return <LoadingComponent />;
    }

    async function deletePromo(id) {
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}del-special`,
            headers: {
                Authorization: `Bearer ${currentUser.token}`
            },
            data: {
                promotionID: id
            }
        });

        history.push("/promotion-management");
    }


    return (
        <div className="bg-white">

            <AdminSearchForm
                heading={promotionID === '0' ? "Add Promotion" : "Edit Promotion"}
                color="text-gray-500"
                header_color="bg-gradient-to-b from-gray-300"
                crumb_color="text-black"
                breadcrumbs={[
                    { name: "Home", href: "/", count: 1 },
                    { name: "User Management", href: "/user-management", count: 2 },
                    { name: promotionID === '0' ? "Add Promotion" : "Edit Promotion", href: "/edit/promotion/" + promotionID, count: 3 },
                ]}
            />

            {/* <AdminNav cartTotal={cartTotal}/> */}
            <br /><br />

            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validate={values => {
                    const errors = {};

                    if (!values.promotion_name) {
                        errors.promotion_name = 'Required';
                    }

                    if (!values.from_date) {
                        errors.from_date = 'Required';
                    }

                    if (!values.to_date) {
                        errors.to_date = 'Required';
                    }

                    if (!values.rules) {
                        errors.rules = 'Rules are Required';
                    } else {
                        if (promotionID === '0') {
                            if (!values.rules[0].ruleType || !values.rules[0].ruleQuantity || !values.rules[0].ruleSize) {
                                errors.rules = 'Rules are Required';
                            }
                        }
                    }

                    return errors;
                }}
                onSubmit={async (values, { setErrors }) => {
                    try {

                        console.log(values)

                        if (promotionID !== '0') {
                            values.promotionID = promotionID


                            await axios({
                                method: "post",
                                url: `${process.env.REACT_APP_API_URL}edit-special`,
                                headers: {
                                    Authorization: `Bearer ${currentUser.token}`,
                                },
                                data: values,
                            });

                            if (values.uploadFile) {

                                var formData = new FormData();
                                var imagefile = document.querySelector("#uploadFile");
                                formData.append("file", imagefile.files[0]);
                                formData.append("promotionID", values.promotionID);

                                await axios({
                                    method: "post",
                                    url: `${process.env.REACT_APP_API_URL}special-flier`,
                                    headers: {
                                        Authorization: `Bearer ${currentUser.token}`,
                                        "Content-Type": "multipart/form-data",
                                    },
                                    data: formData,
                                });
                            }


                        } else {

                            let new_poromo = await axios({
                                method: "post",
                                url: `${process.env.REACT_APP_API_URL}create-special`,
                                headers: {
                                    Authorization: `Bearer ${currentUser.token}`,
                                },
                                data: values,
                            });

                            if (values.uploadFile) {

                                var formDataC = new FormData();
                                var imagefileC = document.querySelector("#uploadFile");
                                formDataC.append("file", imagefileC.files[0]);
                                formDataC.append("promotionID", new_poromo.data);

                                await axios({
                                    method: "post",
                                    url: `${process.env.REACT_APP_API_URL}special-flier`,
                                    headers: {
                                        Authorization: `Bearer ${currentUser.token}`,
                                        "Content-Type": "multipart/form-data",
                                    },
                                    data: formDataC,
                                });
                            }

                        }


                        history.push("/promotion-management")

                    } catch (error) {
                        setErrors({ message: error.response.data.error });
                    }
                }}
            >
                {({ isSubmitting, errors, setFieldValue, values }) => (
                    <Form className="my-8 space-y-6 px-4 sm md:max-w-2xl lg:max-w-4xl xl:max-w-5xl m-auto" action="#" method="POST">

                        <CustomText label="Promotion Name" type="text" name="promotion_name" />

                        <CustomText label="Promotion Full Description" type="text" name="promo_desc" />

                        <div className="grid grid-cols-2 gap-4 pb-10">
                            <div><CustomText label="From Date" type="date" name="from_date" /></div>
                            <div><CustomText label="To Date" type="date" name="to_date" /></div>
                        </div>

                        <hr />

                        <p className="text-2xl">Promotion Rules:</p>

                        {errors.rules ? <p className="text-lk_red text-lg">{errors.rules}</p> : ""}

                        <FieldArray
                            name="rules"
                            render={arrayHelpers => (
                                <div>
                                    {values.rules && values.rules.length > 0 ? (
                                        values.rules.map((rule, index) => (
                                            <div key={index}>
                                                <div className="grid grid-cols-4 gap-2 pb-10">
                                                    <div className="mt-3">
                                                        <label className="block text-sm font-medium text-gray-700 uppercase mb-5">
                                                            Type
                                                        </label>
                                                        <Field component="select" className="w-full focus:ring-gray-500 focus:border-gray-500 border-gray-300" name={`rules.${index}.ruleType`}>
                                                            <option value="0">- Select One -</option>
                                                            {/* <option value="1">Weight</option> */}
                                                            <option value="2">Quantity</option>
                                                        </Field>
                                                    </div>
                                                    <div className="mt-3">
                                                        <label className="block text-sm font-medium text-gray-700 uppercase mb-5">
                                                            Size
                                                        </label>
                                                        <Field component="select" className="w-full focus:ring-gray-500 focus:border-gray-500 border-gray-300" name={`rules.${index}.ruleSize`}>
                                                            <option value="0">- Select One -</option>
                                                            {/* <option value="1">Under</option> */}
                                                            <option value="2">Equals</option>
                                                            <option value="3">Over</option>
                                                        </Field>
                                                    </div>
                                                    <div><CustomText label="Quantity" type="number" name={`rules.${index}.ruleQuantity`} /></div>

                                                    <div className="mt-12">
                                                        <button
                                                            type="button"
                                                            className="lkbutton bg-gray-400 hover:bg-gray-600 w-16 border_radius_10 ml-20"
                                                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                                        >
                                                            -
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="lkbutton bg-red-400 hover:bg-red-600 w-16 border_radius_10 ml-5"
                                                            onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                                                        >
                                                            +
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <button type="button" className="lkbutton bg-lk_red hover:bg-lk_red_hover w-40 border_radius_10" onClick={() => arrayHelpers.push('')}>
                                            Add a rule
                                        </button>
                                    )}
                                    <div>
                                    </div>
                                </div>
                            )}
                        />

                        <hr />

                        <div className="grid grid-cols-2 gap-4 pt-10 pb-10">

                            <div className="mt-3">
                                <label className="block text-sm font-medium text-gray-700 uppercase mb-5">
                                    Special On
                                </label>
                                <select className="w-full focus:ring-gray-500 focus:border-gray-500 border-gray-300" name="special_on" onChange={(value) => { setFieldValue("special_on", value.target.value) }}>
                                    <option>- Select One -</option>
                                    {specialOnTypes && specialOnTypes.length > 0 && specialOnTypes.map((specialOnType, index) => (
                                        <>
                                            {specialOnType.value == values.special_on ?
                                                <option value={specialOnType.value} selected key={index}>{specialOnType.label}</option>
                                                :
                                                <option value={specialOnType.value} key={index}>{specialOnType.label}</option>
                                            }

                                        </>
                                    ))}
                                </select>
                            </div>

                            {/* {values.special_on == "2" ?
                            <div className="mt-3">
                                <label className="block text-sm font-medium text-gray-700 uppercase mb-5">
                                    Special type
                                </label>
                                <select className="w-full focus:ring-gray-500 focus:border-gray-500 border-gray-300" name="special_type" onChange={(value) => {setFieldValue("special_type", value.target.value)}}>
                                    <option>- Select One -</option>
                                    {spcialTypes && spcialTypes.length > 0 && spcialTypes.map((spcialType, index) => (
                                        <>
                                            {spcialType.value == values.special_type ? 
                                                <option value={spcialType.value} selected key={index}>{spcialType.label}</option> 
                                            : 
                                                <option value={spcialType.value} key={index}>{spcialType.label}</option>
                                            } 
                                                
                                        </>
                                    ))}
                                </select>
                            </div>
                         : ""} */}


                            <div className="pt-3">
                                <label className="block text-sm font-medium text-gray-700 uppercase mb-5">
                                    Discount type
                                </label>
                                <select className="w-full focus:ring-gray-500 focus:border-gray-500 border-gray-300" name="discount_type" onChange={(value) => { setTypeOfDiscount(value.target.value); setFieldValue("discount_type", value.target.value) }}>
                                    <option>- Select One -</option>
                                    {discountTypes && discountTypes.length > 0 && discountTypes.map((discountType, index) => (
                                        <>
                                            {discountType.value == values.discount_type ?
                                                <option value={discountType.value} selected key={index}>{discountType.label}</option>
                                                :
                                                <option value={discountType.value} key={index}>{discountType.label}</option>
                                            }

                                        </>
                                    ))}
                                </select>
                            </div>

                            {/* <div><CustomText label="Required Buy Amount" type="number" name="buy_amount" /></div> */}

                            {typeOfDiscount == "1" ?
                                <div><CustomText label="Discount Percentage" type="number" name="discount_percentage" /></div>
                                : typeOfDiscount == "2" ?
                                    <>
                                        <div><CustomText label="Free Amount" type="number" name="free_amount" /></div>
                                        {/* <div className="mt-3">
                                        <label className="block text-sm font-medium text-gray-700 uppercase mb-5">
                                            Free Product type
                                        </label>
                                        <select className="w-full focus:ring-gray-500 focus:border-gray-500 border-gray-300" name="free_product_type" onChange={(value) => {setFieldValue("free_product_type", value.target.value)}}>
                                            <option>- Select One -</option>
                                            {freeProductTypes && freeProductTypes.length > 0 && freeProductTypes.map((freeProductType) => (
                                                <>
                                                    {freeProductType.value == values.free_product_type ? 
                                                        <option value={freeProductType.value} selected>{freeProductType.label}</option> 
                                                    : 
                                                        <option value={freeProductType.value}>{freeProductType.label}</option>
                                                    } 
                                                        
                                                </>
                                            ))}
                                        </select>
                                    </div> */}
                                    </>
                                    : <div></div>}

                        </div>

                        <hr />

                        <div className="grid grid-cols-2 gap-4 pt-10 pb-10">
                            <label className="block text-sm font-medium text-gray-700 uppercase mb-5">Promotion Flier</label><br />
                            <input
                                id="uploadFile"
                                name="uploadFile"
                                type="file"
                                onChange={(event) => {
                                    setFieldValue("uploadFile", event.currentTarget.files[0]);
                                }}
                                className="form-control"
                            />

                            {initialValues.imgDisplay !== "" && initialValues.imgDisplay !== undefined && (
                                <img src={process.env.REACT_APP_URL + "fliers/" + initialValues.imgDisplay} />
                            )}
                        </div>

                        <div className="grid grid-cols-2 gap-4 pt-10 ">
                            <label className="block text-sm font-medium text-gray-700 uppercase mb-5">Customer Class</label>
                        </div>

                        <Select defaultValue={defaultCustClasses} options={custClassesWithSelectAll} onChange={
                            (selectedOptions) => {
                                if (selectedOptions.some((option) => option.value === "*")) {
                                    // Select all options
                                    setCustClasses(custClasses);
                                    setDefaultCustClasses(custClasses);
                                    setFieldValue(
                                        "classes",
                                        custClasses.map((option) => option.value).join(",")
                                    );
                                } else {
                                    // Select individual options
                                    setCustClasses(selectedOptions);
                                    setDefaultCustClasses(selectedOptions);
                                    setFieldValue(
                                        "classes",
                                        selectedOptions.map((option) => option.value).join(",")
                                    );
                                }
                            }
                        } name="classes" isMulti classNamePrefix="select" className=" mt-8" />

                        {/* <div className="grid grid-cols-12 gap-4 pb-10">
                            <label><input type="checkbox" name="OT_class" checked={values.OT_class} onClick={(value) => {
                                setFieldValue("OT_class", value.target.checked);
                                }}/> &nbsp; OT &nbsp; </label>
                            <label><input type="checkbox" name="W1_class" checked={values.W1_class} onClick={(value) => {
                                setFieldValue("W1_class", value.target.checked);
                                }}/> &nbsp; W1 &nbsp; </label>
                            <label><input type="checkbox" name="SS_class" checked={values.SS_class} onClick={(value) => {
                                setFieldValue("SS_class", value.target.checked);
                                }}/> &nbsp; SS &nbsp; </label>
                            <label><input type="checkbox" name="VS_class" checked={values.VS_class} onClick={(value) => {
                                setFieldValue("VS_class", value.target.checked);
                                }}/> &nbsp; VS &nbsp; </label>
                            <label><input type="checkbox" name="VN_class" checked={values.VN_class} onClick={(value) => {
                                setFieldValue("VN_class", value.target.checked);
                                }}/> &nbsp; VN &nbsp; </label>
                            <label><input type="checkbox" name="SN_class" checked={values.SN_class} onClick={(value) => {
                                setFieldValue("SN_class", value.target.checked);
                                }}/> &nbsp; SN &nbsp; </label>
                            <label><input type="checkbox" name="VP_class" checked={values.VP_class} onClick={(value) => {
                                setFieldValue("VP_class", value.target.checked);
                                }}/> &nbsp; VP &nbsp; </label>
                            <label><input type="checkbox" name="PS_class" checked={values.PS_class} onClick={(value) => {
                                setFieldValue("PS_class", value.target.checked);
                                }}/> &nbsp; PS &nbsp; </label>
                            <label><input type="checkbox" name="EX_class" checked={values.EX_class} onClick={(value) => {
                                setFieldValue("EX_class", value.target.checked);
                                }}/> &nbsp; EX &nbsp; </label>
                            <label><input type="checkbox" name="NB_class" checked={values.NB_class} onClick={(value) => {
                                setFieldValue("NB_class", value.target.checked);
                                }}/> &nbsp; NB &nbsp; </label>
                        </div> */}

                        {typeOfDiscount == "2" && (
                            <>
                                <div className="grid grid-cols-2 gap-4 pt-10 ">
                                    <label className="block text-sm font-medium text-gray-700 uppercase mb-5">Customer To Accept Terms</label>
                                </div>
                                <div className="grid grid-cols-2 gap-4 pb-10">
                                    <label><input type="checkbox" name="accept_terms" checked={values.accept_terms} onClick={(value) => {
                                        setFieldValue("accept_terms", value.target.checked);
                                    }} /> &nbsp; Request To Accept Terms &nbsp; </label>
                                </div>
                            </>
                        )}

                        {/* <div className="grid grid-cols-2 gap-4 pt-10 pb-10">

                            <div className="pt-3">
                                <label className="block text-sm font-medium text-gray-700 uppercase mb-5">
                                    Limit on Promotion
                                </label>
                                <select className="w-full focus:ring-gray-500 focus:border-gray-500 border-gray-300" name="limit_on_order" onChange={(value) => {setLimitOnPromotion(value.target.value); setFieldValue("limit_on_order", value.target.value)}}>
                                    <option>- Select One -</option>
                                    {limitTypes && limitTypes.length > 0 && limitTypes.map((limitType, index) => (
                                        <>
                                            {limitType.value == values.limit_on_order ? 
                                                <option value={limitType.value} selected key={index}>{limitType.label}</option> 
                                            : 
                                                <option value={limitType.value} key={index}>{limitType.label}</option>
                                            } 
                                                
                                        </>
                                    ))}
                                </select>
                            </div>

                            {limitOnPromotion == "2" ? 
                                <div><CustomText label="Amount Per Day Allowed" type="number" name="amount_per_day_allowed" /></div> 
                            : 
                                <div></div>
                            }

                        </div> */}

                        <hr />

                        <div className="grid grid-cols-2 gap-4 pt-10 pb-10">

                            <div>
                                <FieldArray
                                    name="reqProducts"
                                    render={arrayHelpers => (
                                        <div>

                                            {values.reqProducts && values.reqProducts.length > 0 ? (
                                                values.reqProducts.map((reqProduct, index) => (
                                                    <div key={index}>
                                                        {index == 0 ? <p className="text-xl">Products For Promotion</p> : ""}
                                                        <CustomText name={`reqProducts.${index}`} type="text" placeholder="Stock Code" />
                                                        <button
                                                            type="button"
                                                            className="lkbutton bg-gray-400 hover:bg-gray-600 w-16 border_radius_10"
                                                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                                        >
                                                            -
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="lkbutton bg-red-400 hover:bg-red-600 w-16 border_radius_10 ml-5"
                                                            onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                                                        >
                                                            +
                                                        </button>
                                                    </div>
                                                ))
                                            ) : (
                                                <button type="button" className="lkbutton bg-lk_red hover:bg-lk_red_hover w-full border_radius_10" onClick={() => arrayHelpers.push('')}>
                                                    Add a Product for Promotion
                                                </button>
                                            )}
                                            <div>
                                            </div>
                                        </div>
                                    )}
                                />
                            </div>

                            {typeOfDiscount == "2" ?

                                <div>
                                    <FieldArray
                                        name="freeProducts"
                                        render={arrayHelpers => (
                                            <div>
                                                {values.freeProducts && values.freeProducts.length > 0 ? (
                                                    values.freeProducts.map((freeProduct, index) => (
                                                        <div key={index}>
                                                            {index == 0 ? <p className="text-xl">Free Products</p> : ""}
                                                            <CustomText name={`freeProducts.${index}`} type="text" placeholder="Stock Code" />
                                                            <button
                                                                type="button"
                                                                className="lkbutton bg-gray-400 hover:bg-gray-600 w-16 border_radius_10"
                                                                onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                                            >
                                                                -
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="lkbutton bg-red-400 hover:bg-red-600 w-16 border_radius_10 ml-5"
                                                                onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                                                            >
                                                                +
                                                            </button>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <button type="button" className="lkbutton bg-lk_red hover:bg-lk_red_hover w-full border_radius_10" onClick={() => arrayHelpers.push('')}>
                                                        Add a Free Product
                                                    </button>
                                                )}
                                                <div>
                                                </div>
                                            </div>
                                        )}
                                    />
                                </div>
                                : ""}

                        </div>



                        {errors.message && <p className="text-lk_red text-lg">{errors.message}</p>}
                        <CustomButton
                            disabled={isSubmitting}
                            submitBool={isSubmitting}
                            type="submit"
                            text={promotionID === '0' ? "Create" : "Update"}
                            className="lkbutton bg-lk_red hover:bg-lk_red_hover w-full border_radius_10"
                        />

                        {promotionID > 0 && (
                            <CustomButton
                                disabled={isSubmitting}
                                submitBool={isSubmitting}
                                type="button"
                                onClick={() => deletePromo(promotionID)}
                                text="Delete"
                                className="lkbutton bg-lk_red hover:bg-lk_red_hover w-full border_radius_10"
                            />
                        )}

                    </Form>
                )}
            </Formik>
        </div>
    )
}