import React from "react";
import CustomHeader from "../../../app/common/elements/CustomHeader";

export default function HowToOrderPage() {
    return (
        <div className="bg-lk_white_backround mb-10 text-center">
            <CustomHeader heading="How To Order" breadcrumbs={[
                {name: "Home", href: '/', count: 1},
                {name: "Online Ordering", href: '/online-ordering', count: 2},
                {name: "How To Order", href: '/online-ordering/how-to-order', count: 3},
            ]}/>

            <p className="text-lk_grey_one px-4 text-2xl sm:text-3xl lg:text-5xl mx-auto my-12 text-center max-w-2xl lg:max-w-4xl xl:max-w-5xl">Please follow the link below to download our detailed guide on ordering Lakato products</p>

            <a
                href="/test_pdf.pdf"
                download
            >
                <button className={"lkbutton border_radius_4 bg-lk_red hover:bg-lk_red_hover h-18 w-48"}>
                    Download PDF
                </button>
            </a>

            <img src="/images/open_account_image.png" alt="Open Account Lakato" className="pt-8 mx-auto"/>
        </div>
    )
}
