import React from "react";
import CustomHeader from "../../app/common/elements/CustomHeader";
import ContactCard from "../../app/common/elements/ContactCard";
import { ContactForm } from "../forms/ContactForm";

export default function ContactUsPage() {
    return (
        <div className="bg-lk_white_backround">
            <CustomHeader heading="Contact Us" breadcrumbs={[
                {name: "Home", href: '/', count: 1},
                {name: "Contact Us", href: '/contact-us', count: 2},
            ]}/>

            <div className="flex flex-wrap justify-center py-10 max-w-7xl m-auto">
                {/* Location Card */}
                <ContactCard 
                    heading="Head Office and Lakato Johannesburg" 
                    address="Unit 10, Girder Road, Droste Park, Benrose, 2094"
                    tel="(011) 624-1524"
                    fax="(011) 624-1574"
                />
                <ContactCard 
                    heading="Lakato Cape Town" 
                    address="6 Cilmor Street, Stikland, Belville, 7535"
                    tel="(021) 944-6900"
                    fax="(021) 948-0559"
                />
                <ContactCard 
                    heading="Lakato Pietermaritzburg" 
                    address="6 Short Retreat Road, Mkondeni, Pietermaritzburg, 3209"
                    tel="(033) 386-3351"
                    fax="(033) 386-5215"
                />
                <ContactCard 
                    heading="Lakato Gqeberha" 
                    address="Erf 117 Greenbushes Business Estate, Old Cape Road Greenbushes, Gqeberha, 3001"
                    tel="(041) 372 1238"
                    fax="(0866) 57 7272"
                />
            </div>

            <ContactForm />
        </div>
    )
}
