import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";

const LoadingComponent = () => {
    return (
        // <Dimmer inverted active={true}>
        //     {prodlength && prodlength !== "" ? (
        //         <Loader content={"Loading... (" + prodlength + " loaded)"} />
        //     ) : (
        //         <Loader content="Loading..." />
        //     )}
        // </Dimmer>
        <div className="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50">
            <span
                className="top-1/2 my-0 mx-auto block relative w-2 h-2"
                style={{ top: "50%" }}
            >
                <FontAwesomeIcon
                    className="animate-spin"
                    size="6x"
                    icon={faSpinnerThird}
                />
            </span>
        </div>
    );
};

export default LoadingComponent;
