import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { usePagination, useTable, useSortBy } from 'react-table';
import AdminSearchForm from '../../../forms/AdminSearchForm';
import AdminNav from '../../../nav/AdminNav';


export function PromotionManagement(props) {

    const { currentUser } = useSelector((state) => state.auth);

    let pathname = props.location.pathname;
    let newPathname = pathname.replace("/", "");
    let pageName = newPathname.replaceAll("-", " ");

    const [cartTotal, setCartTotal] = useState([]);
    const [specials, setSpecials] = useState([])

    useEffect(() => {
        setCartTotal(
            parseFloat(window.sessionStorage.getItem("itemsInBasket"))
        );
        getSpecials();
    }, []);

    async function getSpecials(){

        let specialList = await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}get-all-specials`,
            headers: { 
                Authorization: `Bearer ${currentUser.token}`
             },
        });

        let allSpecials = specialList.data
        let specialData = []

        console.log(allSpecials)
        
        await Object.keys(allSpecials).forEach(function (key) {
            specialData.push({
                col0: allSpecials[key].id,
                col1: allSpecials[key].promotion_name,
                col2: allSpecials[key].from_date,
                col3: allSpecials[key].to_date,
                col4: new Date(new Date(allSpecials[key].from_date).toDateString()) <= new Date(new Date().toDateString()) && new Date(new Date(allSpecials[key].to_date).toDateString()) >= new Date(new Date().toDateString()) ? "Active" : "Expired",
            });
        });
        


        setSpecials(specialData)

    }

    const data = React.useMemo(() => specials, [specials])

    const columns = React.useMemo(
        () => [
          {
            Header: () => (
                <div
                  style={{
                    textAlign:"left"
                  }}
                >Special Name</div>),
            accessor: 'col1',
          },
          {
            Header: () => (
                <div
                  style={{
                    textAlign:"left"
                  }}
                >From Date</div>),
            accessor: 'col2',
          },
          {
            Header: () => (
                <div
                  style={{
                    textAlign:"left"
                  }}
                >To Date</div>),
            accessor: 'col3',
          },
          {
            Header: () => (
                <div
                  style={{
                    textAlign:"left"
                  }}
                >Status</div>),
            accessor: 'col4',
            Cell: rowInfo => {
                return (
                    <>
                        {rowInfo.row.original.col4 == "Active" ? 
                            <p className="text-xl text-center text-green-500">Active</p>
                        : 
                            <p className="text-xl text-center text-red-500">Inactive</p>
                        }
                    </>
                );
              },
          },
          {
            Header: '',
            accessor: 'col0',
            Cell: rowInfo => {
                return (
                    <div>
                        
                        <Link
                            as="a"
                            to={"/edit/promotion/"+rowInfo.row.original.col0}
                            className="hover:text-lk_dark_grey_button_hover"
                        >
                            <button className="px-10 py-2 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase mx-auto">
                                Edit
                            </button>
                        </Link>
                    </div>
                );
              },
          },
         
        ],
        []
      )

      const tableInstance = useTable({ columns, data }, useSortBy, usePagination)

      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        prepareRow,
      } = tableInstance

    return (
        <>
        <div className="bg-gray-50">
            <AdminSearchForm
                heading={pageName}
                color="text-gray-500"
                header_color="bg-gradient-to-b from-gray-300"
                crumb_color="text-black"
                breadcrumbs={[
                    { name: "Home", href: "/", count: 1 },
                    { name: pageName, href: pathname, count: 2 },
                ]}
            />

            {/* <AdminNav cartTotal={cartTotal} /> */}
            <br/><br/>

            <div className="container mx-auto flex flex-row-reverse">
                <Link
                    as="a"
                    to={"/edit/promotion/0"}
                    className="hover:text-lk_dark_grey_button_hover"
                >
                    <button className="px-10 py-2 mb-5 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase mx-auto">
                        Add new Promotion
                    </button>
                </Link>
            </div>

            <div className="container mx-auto">

            

                <table {...getTableProps()} className="mb-10 w-full" style={{borderCollapse: "separate", borderSpacing: "0 1em"}}>
                    <thead>
                    {
                    headerGroups.map(headerGroup => (
                        
                        <tr {...headerGroup.getHeaderGroupProps()} className="bg-gray-100">
                        {
                        headerGroup.headers.map(column => (
                            
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className="px-2 py-5 font-light">
                            {column.render('Header')}
                            </th>
                        ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {
                    page.map(row => {
                        
                        prepareRow(row)
                        return (
                        
                        <tr {...row.getRowProps()} className="bg-white rounded mt-5">
                            {
                            row.cells.map(cell => {
                            
                            return (
                                <td {...cell.getCellProps()} style={{borderRight: "1px solid #EFEFEF", textAlign: "left"}} className="mx-auto text-center relative px-2 py-2 text-sm">
                                    {cell.render('Cell')}
                                </td>
                            )
                            })}
                        </tr>
                        )
                    })}
                    </tbody>
                </table>

                <nav
                    className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 bg-gray-50"
                    aria-label="Pagination"
                >
                    <div className="flex-1 flex justify-between sm:justify-end">
                        <button
                            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                        >
                            Previous
                        </button>
                        <button
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                        >
                            Next
                        </button>
                    </div>
                </nav>


            </div>
        </div>


        

    </>
    )    
}