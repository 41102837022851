import { faHeart } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import AdminSearchForm from "../../../forms/AdminSearchForm";
import AdminNav from "../../../nav/AdminNav";
import { useTable, usePagination, useSortBy, useResizeColumns } from "react-table";
import Select from "react-select";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { faCaretDown, faCaretUp, faExclamationCircle, faHeart as solidHeart, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { openModal } from "../../../../app/common/modals/modalReducer";
import ReactTooltip from "react-tooltip";

export default function ProductPage(props) {
    let history = useHistory();
    const dispatch = useDispatch();

    const { currentUser } = useSelector((state) => state.auth);

    let pathname = props.location.pathname;
    let newPathname = pathname.replace("/shop-", "");
    let pageName = newPathname.replaceAll("-", " ");

    const [favourites, setFavourites] = useState([]);
    const [products, setProducts] = useState([]);
    const [cartTotal, setCartTotal] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [recordCount, setRecordCount] = useState("0");
    const [sortBy, setSortBy] = useState("Description");
    const [sortDirection, setSortDirection] = useState("asc");
    const [pageSizeValue, setPageSizeValue] = useState(10);

    if (currentUser.multi_account === true && (currentUser.customer_code == null || currentUser.customer_code == "None" || currentUser.customer_code.split(",").length > 1)) {
        window.location.replace("/select-profile");
    }

    useEffect(() => {
        getProducts(0, pageSizeValue);
        setCartTotal(parseFloat(window.sessionStorage.getItem("itemsInBasket")));
    }, [sortBy, sortDirection]);

    async function getProducts(pageNo, pageSizeValue) {
        let favouritesResults = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}favourites`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                userId: currentUser.id,
            },
        });

        let favData = favouritesResults.data[0];
        let favIds = [];

        await Object.keys(favData).forEach(function (key) {
            favIds.push(favData[key].favourite_product_id);
        });

        setFavourites(favIds);

        console.log(pageSizeValue);

        let productResults = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}get-products`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                userId: currentUser.id,
                priceCode:
                    currentUser.isCustomer === 1 && currentUser.customer_code !== null
                        ? currentUser.customer_code
                        : currentUser.customer_code === null || currentUser.customer_code.split(",").length > 1
                        ? "None"
                        : currentUser.customer_code,
                category: pageName,
                pageNumber: pageNo,
                type: "",
                sortBy: sortBy,
                sortDirection: sortDirection,
                branch: currentUser.branch,
                pageSizeValue: pageSizeValue,
            },
        });

        let prodData = productResults.data[0];
        setRecordCount(productResults.data[1][0][""]);

        let allproducts = [];
        let totalItems = JSON.parse(window.sessionStorage.getItem("totalItemsInBasket"));

        await Object.keys(prodData).forEach(function (key) {
            let newStockCode = prodData[key].StockCode;
            newStockCode = newStockCode.replace(" ", "");

            if (newStockCode) {
                let curfav = false;

                if (favIds.includes(prodData[key].StockCode.trim())) {
                    curfav = true;
                }

                let total = "0";
                if (totalItems !== null) {
                    Object.keys(totalItems).forEach(function (index) {
                        if (totalItems[index][1].trim() == prodData[key].StockCode.trim()) {
                            total = totalItems[index][0];
                        }
                    });
                }

                allproducts.push({
                    col0: prodData[key].col0,
                    col1: prodData[key].col1,
                    col2: prodData[key].StockCode,
                    col3: prodData[key].Description,
                    col4: prodData[key].InvWarehouse,
                    col5: prodData[key].InvPrice[0] ? "R " + parseFloat(prodData[key].InvPrice[0].SellingPrice).toFixed(2) : "Free",
                    col6: total,
                    col7: curfav,
                    col8: prodData[key].LongDesc,
                    col9: prodData[key].altStock,
                    col10: prodData[key].ProductGroup,
                    col11: prodData[key].AlternateUom,
                });
            }
        });

        setProducts(allproducts);
    }

    async function nextPageNavigate(pageNumber, pageSizeValue) {
        let pageNo = parseFloat(pageNumber);
        let newNo = pageNo + 1;

        setPageNumber(newNo);

        getProducts(newNo, pageSizeValue);
    }

    async function previousPageNavigate(pageNumber, pageSizeValue) {
        let pageNo = parseFloat(pageNumber);
        let newNo = pageNo - 1;

        setPageNumber(newNo);

        getProducts(newNo, pageSizeValue);
    }

    async function addFavourite(rowId, pageNumber, pageSizeValue) {
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}add-new-favourite`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                rowId: rowId,
                userId: currentUser.id,
            },
        });

        getProducts(pageNumber, pageSizeValue);
    }

    async function removeFavourite(rowId) {
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}remove-favourite`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                rowId: rowId,
                userId: currentUser.id,
            },
        });

        getProducts();
    }
    async function addItemToCart(itemId) {
        //   itemId = itemId.trim();
        let prevItemClicked = JSON.parse(window.sessionStorage.getItem("itemsClicked"));
        if (prevItemClicked !== null) {
            window.sessionStorage.setItem("itemsClicked", JSON.stringify([...prevItemClicked, itemId]));
        } else {
            window.sessionStorage.setItem("itemsClicked", JSON.stringify(itemId));
        }

        let prevItemIds = JSON.parse(window.sessionStorage.getItem("itemsIdsInBasket"));

        if (!prevItemIds.includes(itemId)) {
            window.sessionStorage.setItem("itemsIdsInBasket", JSON.stringify([...prevItemIds, itemId]));

            let CurrentCartAmount = window.sessionStorage.getItem("itemsInBasket");
            let NewAmount = parseFloat(CurrentCartAmount) + 1;
            window.sessionStorage.setItem("itemsInBasket", NewAmount);
            setCartTotal(NewAmount);
        } else {
            let totalItemsClicked = JSON.parse(window.sessionStorage.getItem("itemsClicked"));
            let thisItem = 0;

            await Object.keys(totalItemsClicked).forEach(function (key) {
                if (totalItemsClicked[key] === itemId) {
                    thisItem += 1;
                }
            });

            let prevItemTotals = JSON.parse(window.sessionStorage.getItem("totalItemsInBasket"));
            if (prevItemTotals !== null) {
                window.sessionStorage.setItem("totalItemsInBasket", JSON.stringify([...prevItemTotals, [thisItem, itemId]]));
            } else {
                window.sessionStorage.setItem("totalItemsInBasket", JSON.stringify([[thisItem, itemId]]));
            }
        }
    }

    async function changePageSize(value) {
        setPageSizeValue(value);
        getProducts(0, value);
    }

    async function setTotals(totalAmount, itemId) {
        let prevItemTotals = JSON.parse(window.sessionStorage.getItem("totalItemsInBasket"));
        let found = false;

        Object.keys(prevItemTotals).forEach(function (key) {
            if (prevItemTotals[key][1].trim() === itemId.trim()) {
                found = true;
                prevItemTotals[key][0] = totalAmount;
            }
        });

        if (prevItemTotals !== null) {
            if (found === false) {
                window.sessionStorage.setItem("totalItemsInBasket", JSON.stringify([...prevItemTotals, [totalAmount, itemId]]));
            } else {
                window.sessionStorage.setItem("totalItemsInBasket", JSON.stringify([...prevItemTotals]));
            }
        } else {
            window.sessionStorage.setItem("totalItemsInBasket", JSON.stringify([[totalAmount, itemId]]));
        }
    }

    const data = React.useMemo(() => products, [products]);

    console.log(products);

    const columns = React.useMemo(
        () => [
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "left",
                        }}
                    >
                        Image
                    </div>
                ),
                accessor: "col1",
                Cell: (tableProps) => <img src={process.env.REACT_APP_URL + "/images/placeholder-image.png"} width={150} alt="Product" className="mx-auto px-5 py-5" />,
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "left",
                        }}
                        id="StockCode"
                        className="cursor-pointer"
                    >
                        Code
                        {sortBy === "StockCode" && sortDirection === "asc" ? (
                            <FontAwesomeIcon icon={faCaretUp} className={sortBy === "StockCode" ? "text-blue-600 ml-10 self-center" : "text-gray-400 ml-10 self-center"} />
                        ) : (
                            <FontAwesomeIcon icon={faCaretDown} className={sortBy === "StockCode" ? "text-blue-600 ml-10 self-center" : "text-gray-400 ml-10 self-center"} />
                        )}
                    </div>
                ),
                accessor: "col2",
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "left",
                        }}
                        id="Description"
                        className="cursor-pointer"
                    >
                        Description
                        {sortBy === "Description" && sortDirection === "asc" ? (
                            <FontAwesomeIcon icon={faCaretUp} className={sortBy === "Description" ? "text-blue-600 ml-10 self-center" : "text-gray-400 ml-10 self-center"} />
                        ) : (
                            <FontAwesomeIcon icon={faCaretDown} className={sortBy === "Description" ? "text-blue-600 ml-10 self-center" : "text-gray-400 ml-10 self-center"} />
                        )}
                    </div>
                ),
                accessor: "col3",
                Cell: (rowInfo) => {
                    return (
                        <>
                            <p>{rowInfo.row.original.col3}</p>
                            <p>{rowInfo.row.original.col8}</p>
                            <p className="text-red-500">
                                {rowInfo.row.original.col10 == "PG008" || rowInfo.row.original.col10 == "PG003" || rowInfo.row.original.col10 == "PG007" || rowInfo.row.original.col10 == "PG001" ? (
                                    <>
                                        <br />
                                        {rowInfo.row.original.col10 == "PG008" ? (
                                            <>
                                                <p data-tip="This is a product registered under the Medicines and Related Substance Act 101 of 1965 as Schedule 6 and requires a prescription to be submitted to Lakato. This product is non-returnable">
                                                    <FontAwesomeIcon icon={faQuestionCircle} /> NON-RETURNABLE{" "}
                                                </p>{" "}
                                                <ReactTooltip />
                                            </>
                                        ) : (
                                            ""
                                        )}

                                        {rowInfo.row.original.col10 == "PG003" || rowInfo.row.original.col10 == "PG007" ? (
                                            <>
                                                <p data-tip="This is a cold-chain product and is non-returnable">
                                                    <FontAwesomeIcon icon={faQuestionCircle} /> NON-RETURNABLE{" "}
                                                </p>{" "}
                                                <ReactTooltip />
                                            </>
                                        ) : (
                                            ""
                                        )}

                                        {rowInfo.row.original.col10 == "PG001" ? (
                                            <>
                                                <p data-tip="This product registered under the Medicines and Related Substance Act 101 of 1965 and is non-returnable">
                                                    <FontAwesomeIcon icon={faQuestionCircle} /> NON-RETURNABLE{" "}
                                                </p>{" "}
                                                <ReactTooltip />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </>
                                ) : (
                                    ""
                                )}
                            </p>
                        </>
                    );
                },
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "left",
                        }}
                        id="QtyOnHand"
                        className="cursor-pointer"
                    >
                        Stock Status
                        {sortBy === "QtyOnHand" && sortDirection === "asc" ? (
                            <FontAwesomeIcon icon={faCaretUp} className={sortBy === "QtyOnHand" ? "text-blue-600 ml-10 self-center" : "text-gray-400 ml-10 self-center"} />
                        ) : (
                            <FontAwesomeIcon icon={faCaretDown} className={sortBy === "QtyOnHand" ? "text-blue-600 ml-10 self-center" : "text-gray-400 ml-10 self-center"} />
                        )}
                    </div>
                ),
                accessor: "col4",
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "left",
                        }}
                        id="price"
                        className="cursor-pointer"
                    >
                        Price Excl. VAT
                        {sortBy === "price" && sortDirection === "asc" ? (
                            <FontAwesomeIcon icon={faCaretUp} className={sortBy === "price" ? "text-blue-600 ml-10 self-center" : "text-gray-400 ml-10 self-center"} />
                        ) : (
                            <FontAwesomeIcon icon={faCaretDown} className={sortBy === "price" ? "text-blue-600 ml-10 self-center" : "text-gray-400 ml-10 self-center"} />
                        )}
                    </div>
                ),
                accessor: "col5",
                Cell: (rowInfo) => {
                    return (
                        <>
                            <p>
                                {rowInfo.row.original.col5}

                                {rowInfo.row.original.col11 == "SD" ? (
                                    <>
                                        <span className="text-green-500 ml-2" data-tip="Settlement Discount if Applicable">
                                            <FontAwesomeIcon icon={faExclamationCircle} />
                                        </span>{" "}
                                        <ReactTooltip />
                                    </>
                                ) : (
                                    ""
                                )}
                            </p>
                        </>
                    );
                },
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "left",
                        }}
                    >
                        QTY
                    </div>
                ),
                accessor: "col6",
                Cell: (rowInfo) => {
                    return (
                        <div>
                            {rowInfo.row.original.col4 === "Out of Stock" ? (
                                ""
                            ) : (
                                <input
                                    type="number"
                                    min="0"
                                    defaultValue={rowInfo.row.original.col6}
                                    className="shadow-sm text-sm border border_radius_5 w-20"
                                    onChange={(values) => {
                                        parseFloat(values.target.value) < 0 ? setTotals(0, rowInfo.row.original.col2) : setTotals(values.target.value, rowInfo.row.original.col2);
                                        values.target.value = Math.abs(values.target.value);
                                    }}
                                />
                            )}
                        </div>
                    );
                },
            },
            {
                Header: "",
                accessor: "col8",
                Cell: (rowInfo) => {
                    return (
                        <div>
                            {rowInfo.row.original.col4 === "Out of Stock" ? (
                                <>
                                    {parseFloat(rowInfo.row.original.col9.length) > 0 ? (
                                        <>
                                            <button
                                                className="px-2 py-2 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase mx-auto"
                                                onClick={() => dispatch(openModal({ modalType: "Alternative", modalProps: { stockCode: rowInfo.row.original.col2 } }))}
                                            >
                                                See Alternative
                                            </button>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                    {/* No Alternative Available */}
                                </>
                            ) : (
                                ""
                            )}
                        </div>
                    );
                },
            },
            {
                Header: "",
                accessor: "col7",
                Cell: (rowInfo) => {
                    return (
                        <div>
                            {rowInfo.row.original.col7 === true ? (
                                <FontAwesomeIcon icon={solidHeart} className="absolute top-3 right-3 text-xl text-gray-500 cursor-pointer" onClick={() => removeFavourite(rowInfo.row.original.col2)} />
                            ) : (
                                <FontAwesomeIcon
                                    icon={faHeart}
                                    className="absolute top-3 right-3 text-xl text-gray-500 cursor-pointer"
                                    onClick={() => addFavourite(rowInfo.row.original.col2, pageNumber, pageSizeValue)}
                                />
                            )}

                            {/* {JSON.parse(window.sessionStorage.getItem("itemsIdsInBasket")) !== null && JSON.parse(
                                        window.sessionStorage.getItem(
                                            "itemsIdsInBasket"
                                        )
                                    ).includes(
                                        (rowInfo.row.original.col2).trim()
                                    ) ? (
                                        <>
                                            <button
                                                className="px-4 py-2 mr-5 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase mx-auto"
                                                onClick={() =>
                                                    history.push("/view-cart")
                                                }
                                            >
                                                View Cart
                                            </button>
                                        </>
                                    ) : (
                                        <> */}
                            {rowInfo.row.original.col4 === "Out of Stock" ? (
                                ""
                            ) : (
                                <>
                                    <button
                                        className="px-4 py-2 mr-5 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase mx-auto"
                                        onClick={() => addItemToCart(rowInfo.row.original.col2)}
                                    >
                                        Add to cart
                                    </button>
                                </>
                            )}

                            {/* </>
                                    )} */}
                        </div>
                    );
                },
            },
        ],
        [sortBy, sortDirection]
    );

    const tableInstance = useTable({ columns, data }, useSortBy, usePagination, useResizeColumns);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        prepareRow,
        pageIndex,
        pageOptions,
        gotoPage,
        pageCount,
        pageSize,
        setPageSize,
    } = tableInstance;

    return (
        <div className="bg-gray-50">
            <AdminSearchForm
                heading={pageName}
                color="text-gray-500"
                header_color="bg-gradient-to-b from-gray-300"
                crumb_color="text-black"
                breadcrumbs={[
                    { name: "Home", href: "/", count: 1 },
                    { name: "Shop", href: "/shop", count: 2 },
                    { name: pageName, href: pathname, count: 3 },
                ]}
            />

            {/* <AdminNav cartTotal={cartTotal} /> */}
            <br />
            <br />

            <div className="container mx-auto">
                <table
                    {...getTableProps()}
                    className="mb-10 w-full"
                    style={{
                        borderCollapse: "separate",
                        borderSpacing: "0 1em",
                    }}
                    responsive
                >
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()} className="bg-gray-100">
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps()}
                                        className="px-2 py-5 font-light"
                                        onClick={(value) => {
                                            setSortBy(value.target.id);
                                            value.target.id == sortBy && sortDirection == "asc" ? setSortDirection("desc") : setSortDirection("asc");
                                        }}
                                    >
                                        {column.render("Header")}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} className="bg-white rounded mt-5">
                                    {row.cells.map((cell) => {
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                                style={{
                                                    borderRight: "1px solid #EFEFEF",
                                                    textAlign: "left",
                                                }}
                                                className="mx-auto text-center relative px-2 py-2 text-sm"
                                            >
                                                {cell.render("Cell").props.cell.column.id === "col2" || cell.render("Cell").props.cell.column.id === "col4" ? (
                                                    <span className="text-gray-500" style={{ textAlign: "left" }}>
                                                        {cell.render("Cell")}
                                                    </span>
                                                ) : (
                                                    cell.render("Cell")
                                                )}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                <nav className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 bg-gray-50" aria-label="Pagination">
                    <div className="hidden sm:block">
                        <div className="flex">
                            <div>
                                <select
                                    value={pageSizeValue}
                                    onChange={(e) => {
                                        changePageSize(e.target.value);
                                        setPageSize(Number(e.target.value));
                                    }}
                                >
                                    {[10, 25, 50].map((pageSize) => (
                                        <option key={pageSize} value={pageSize}>
                                            Show {pageSize}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="ml-3">
                                <p className="text-sm text-gray-700">
                                    Page <span className="font-medium">{parseFloat(pageNumber) + 1}</span> of{" "}
                                    <span className="font-medium">{Math.ceil(parseFloat(recordCount) / Number(pageSizeValue))}</span>
                                </p>
                                <p className="text-sm text-gray-700">
                                    Showing <span className="font-medium">{parseFloat(pageNumber) === 0 ? "1" : parseFloat(pageNumber) * pageSizeValue + 1}</span> to{" "}
                                    <span className="font-medium">
                                        {parseFloat(pageNumber) === 0
                                            ? pageSizeValue
                                            : parseFloat(pageNumber) * Number(pageSizeValue) + Number(pageSizeValue) > recordCount
                                            ? recordCount
                                            : parseFloat(pageNumber) * Number(pageSizeValue) + Number(pageSizeValue)}
                                    </span>{" "}
                                    of <span className="font-medium">{recordCount}</span> results
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 flex justify-between sm:justify-end">
                        <button
                            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                            onClick={() => previousPageNavigate(pageNumber, pageSizeValue)}
                            disabled={pageNumber === 0}
                        >
                            Previous
                        </button>
                        <button
                            onClick={() => nextPageNavigate(pageNumber, pageSizeValue)}
                            disabled={parseFloat(pageNumber) * 10 + 10 > recordCount}
                            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                        >
                            Next
                        </button>
                    </div>
                </nav>
            </div>
        </div>
    );
}
