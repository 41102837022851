import { Formik, Form } from "formik";
import axios from "axios";
import * as Yup from 'yup';
import CustomButton from "../../../app/common/form/CustomButton";
import CustomText from "../../../app/common/form/CustomText";
import AdminSearchForm from "../../forms/AdminSearchForm";
import AdminNav from "../../nav/AdminNav";
import { useDispatch, useSelector } from "react-redux";
import CustomTextArea from "../../../app/common/form/CustomTextArea";
import { useState } from "react";
import { useEffect } from "react";
import { openModal } from "../../../app/common/modals/modalReducer";
import { useHistory } from "react-router";

export function CustomCodeEdit(props) {
    const dispatch = useDispatch();
    const { currentUser } = useSelector((state) => state.auth);
    const [initialValues, setInitialValues] = useState({stock_code: "", custom_code: ""})
    let history = useHistory();
    const [hasCodeError, setHasCodeError] = useState(false)

   

    useEffect(() => {
        getThisCode();
    }, [])

    async function getThisCode(){

        if(props.match.params.custom_code_id !== 0){
            let codes = await axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}get-this-code`,
                headers: { 
                    Authorization: `Bearer ${currentUser.token}`
                 },
                data: {userUid: currentUser.id, code_id: props.match.params.custom_code_id, customer_code: currentUser.customer_code},
            });

            setInitialValues({stock_code: codes.data.stock_code, custom_code: codes.data.custom_code})

        }
    }

    async function validateCode(codeValue){
        let code = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}validate-code`,
            headers: { 
                Authorization: `Bearer ${currentUser.token}`
             },
            data: {codeValue: codeValue},
        });

        if(code.data.length === 0){
            setHasCodeError(true)
        }else{
            setHasCodeError(false)
        }

    }



    return (
        <div className="bg-white">

            <br/><br/>
            

            <Formik
                initialValues={initialValues}
                enableReinitialize="true"
                validationSchema={Yup.object({
                    stock_code: Yup.string().required('Required'),
                    custom_code: Yup.string().required('Required'),
                })}
                onSubmit={async (values, {setErrors, resetForm}) => {
                    try {

                        await axios({
                            method: "post",
                            url: `${process.env.REACT_APP_API_URL}code-update`,
                            headers: { 
                                Authorization: `Bearer ${currentUser.token}`
                             },
                            data: {
                                id: currentUser.id,
                                stock_code: values.stock_code,
                                custom_code: values.custom_code,
                                code_id: props.match.params.custom_code_id,
                                customer_code: currentUser.customer_code,
                            },
                        });

                        history.push("/custom_codes")


                    } catch (error) {
                        setErrors({ message: error.response.data.error });
                    }
                }}
            >
                {({isSubmitting, errors}) => (
                    <Form className="my-8 space-y-6 px-4 sm md:max-w-2xl lg:max-w-4xl xl:max-w-5xl m-auto" action="#" method="POST">

                        <p className="text-xl">{props.match.params.custom_code_id == 0 ? "Add" : "Edit"} Custom Code</p>

                        <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-4">
                            <div><CustomText label="Stock Code" type="text" name="stock_code" onKeyUp={(value) => validateCode(value.target.value)}/>
                            {hasCodeError === true ? <p className="text-red-400">Not a valid code</p> : ""}</div>
                            <div><CustomText label="my Custom Code" type="text" name="custom_code" /></div>
                        </div>
                        
                        <CustomButton
                            disabled={hasCodeError === true || isSubmitting === true}
                            submitBool={isSubmitting}
                            type="submit"
                            text="Submit"
                            className="lkbutton bg-lk_red hover:bg-lk_red_hover w-full border_radius_10"
                        />

                    </Form>                
                )}
            </Formik>
        </div>
    )    
}