import { Formik, Form } from "formik";
import axios from "axios";
import * as Yup from 'yup';
import CustomButton from "../../../app/common/form/CustomButton";
import CustomText from "../../../app/common/form/CustomText";
import AdminSearchForm from "../../forms/AdminSearchForm";
import AdminNav from "../../nav/AdminNav";
import { useDispatch, useSelector } from "react-redux";
import CustomTextArea from "../../../app/common/form/CustomTextArea";
import { useState } from "react";
import { useEffect } from "react";
import { openModal } from "../../../app/common/modals/modalReducer";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

export function CustomCodes() {
    const dispatch = useDispatch();
    const { currentUser } = useSelector((state) => state.auth);
    const [codeList, setCodeList] = useState([])
    const [filteredCodes, setFilteredCodes] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [sortConfig, setSortConfig] = useState({ key: "stock_code", direction: "asc" });
    const [codeLoading, setCodeLoading] = useState(false);

    if(currentUser.multi_account === true && (currentUser.customer_code == null || currentUser.customer_code == "None" || currentUser.customer_code.split(",").length > 1) ){
        window.location.replace('/select-profile')
    }

    useEffect(() => {
        getCodes();
    }, [])

    async function getCodes(){
        let codes = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}get-codes-list`,
            headers: { 
                Authorization: `Bearer ${currentUser.token}`
             },
            data: {userUid: currentUser.id, customer_code: currentUser.customer_code},
        });

        setCodeList(codes.data)
    }

    async function addNewItems(file){
        setCodeLoading(true)

        var formData = new FormData();
        var imagefile = document.querySelector("#importCodes");
        formData.append("file", imagefile.files[0]);
        formData.append("userId", currentUser.id);
        formData.append("customer_code", currentUser.customer_code);

        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}import-to-codes`,
            headers: { 
                Authorization: `Bearer ${currentUser.token}`,
                "Content-Type": "multipart/form-data",
                },
            data: formData,
        });

        setCodeLoading(false)
        getCodes();

    }

    async function deleteThisCode(codeId){
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}delete-code`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {codeId: codeId},
        });

        getCodes();
    }

    useEffect(() => {
        if (searchQuery === "") {
            setFilteredCodes(codeList);
        } else {
            setFilteredCodes(
                codeList.filter(code =>
                    code.stock_code.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    code.custom_code.toLowerCase().includes(searchQuery.toLowerCase())
                )
            );
        }
    }, [searchQuery, codeList]);

    // Handle sorting
    function sortTable(key) {
        let direction = "asc";
        if (sortConfig.key === key && sortConfig.direction === "asc") {
            direction = "desc";
        }
        const sortedData = [...filteredCodes].sort((a, b) => {
            if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
            if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
            return 0;
        });
        setFilteredCodes(sortedData);
        setSortConfig({ key, direction });
    }

    return (
        <div className="bg-white my-8 space-y-6 px-4 sm md:max-w-2xl lg:max-w-4xl xl:max-w-5xl m-auto">
            <h1 className="text-xl">Custom Codes</h1>

            <div className="container mx-auto relative">
                <div className="flex justify-between items-center mb-4">
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="border border-gray-300 p-2 rounded-md"
                    />
                    <div className="flex">

                        <Link
                            className="inline-flex items-right px-12 py-2 ml-3 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_red hover:bg-lk_red_hover  uppercase"
                            as="a"
                            to="/edit_custom_codes/0"
                        >Add New Code</Link>
                        <CustomButton
                            disabled={codeLoading}
                            submitBool={codeLoading}
                            type="submit"
                            text="Import Codes"
                            className="inline-flex items-right px-12 py-2 ml-3 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-blue-500 hover:bg-blue-700 uppercase"
                            onClick={() =>
                                document.getElementById("importCodes").click()
                            }
                            data-tip="Stock Code in column A, Custom Code in column B"
                        />
                        <input
                            id="importCodes"
                            name="importCodes"
                            type="file"
                            style={{ display: "none" }}
                            onChange={(event) => {
                                addNewItems(event.target.files[0]);
                            }}
                            className="form-control"
                        />
                        <ReactTooltip />
                    </div>
                </div>
            </div>

            <br/>

            <table style={{width:"100%"}} className="table-auto w-full border-collapse border border-gray-300">
                <thead>
                    <tr className="bg-gray-200 text-left">
                    <th 
                        className="px-4 py-2 cursor-pointer" 
                        onClick={() => sortTable("stock_code")}
                    >
                        Stock Code 
                        <span className={`ml-1 ${sortConfig.key === "stock_code" ? "font-bold" : "text-gray-500"}`}>
                            {sortConfig.key === "stock_code" && sortConfig.direction === "asc" ? "▲" : "▼"}
                        </span>
                    </th>
                    <th 
                        className="px-4 py-2 cursor-pointer" 
                        onClick={() => sortTable("custom_code")}
                    >
                        My Code 
                        <span className={`ml-1 ${sortConfig.key === "custom_code" ? "font-bold" : "text-gray-500"}`}>
                            {sortConfig.key === "custom_code" && sortConfig.direction === "asc" ? "▲" : "▼"}
                        </span>
                    </th>
                        <th className="px-4 py-2">Action</th>
                    </tr>
                </thead>
                {/* {codeList.map((code) => {
                    return(
                        <tr>
                            <td className="border border-slate-300 px-5 py-5">{code.stock_code}</td>
                            <td className="border border-slate-300 px-5 py-5">{code.custom_code}</td>
                            <td className="border border-slate-300 px-5 py-5">
                                <Link
                                    className="px-12 py-2 ml-3 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_red hover:bg-lk_red_hover"
                                    as="a"
                                    to={"/edit_custom_codes/"+code.id}
                                >Edit</Link>
                                <button className="px-12 py-2 ml-3 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-gray-500 hover:bg-gray-600" onClick={() => window.confirm("Are you sure you wish to delete this item?") && deleteThisCode(code.id)}>
                                    Delete
                                </button>
                            </td>
                        </tr>
                    )
                })} */}
                <tbody>
                    {filteredCodes.length > 0 ? (
                        filteredCodes.map((code) => (
                            <tr key={code.id} className="border border-gray-300">
                                <td className="px-4 py-2">{code.stock_code}</td>
                                <td className="px-4 py-2">{code.custom_code}</td>
                                <td className="px-4 py-2 flex space-x-2">
                                    <Link
                                        className="px-4 py-2 text-white bg-lk_red hover:bg-lk_red_hover"
                                        to={`/edit_custom_codes/${code.id}`}
                                    >
                                        Edit
                                    </Link>
                                    <button
                                        className="px-4 py-2 text-white bg-gray-500 hover:bg-gray-600"
                                        onClick={() =>
                                            window.confirm("Are you sure you wish to delete this item?") &&
                                            deleteThisCode(code.id)
                                        }
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="3" className="px-4 py-2 text-center">No results found</td>
                        </tr>
                    )}
                </tbody>
            </table>
            
        </div>
    )    
}