import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import CustomButton from "../../app/common/form/CustomButton";
import { closeModal } from "../../app/common/modals/modalReducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/pro-regular-svg-icons";
import { useState } from "react";

export function ConfirmRepeatOrder(props) {
    const dispatch = useDispatch();
    let history = useHistory();
    const { currentUser } = useSelector((state) => state.auth);

    let orderId = props.orderId

    const [isSubmitting, setIsSubmitting] = useState(false)

    async function confirmRepeat(orderId){
        setIsSubmitting(true)

        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}repeat-this-order`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
              userId: currentUser.id,
              orderId: orderId
            },
          });

        setIsSubmitting(false)
        history.push("/orders")
        dispatch(closeModal())
      }

    return (
        <div className="fixed z-50 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                    className="fixed inset-0 transition ease-in duration-300"
                    aria-hidden="true"
                >
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                </div>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                    &#8203;
                </span>
                
                <div 
                    className="
                        inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 
                        overflow-hidden shadow-xl transform transition-all 
                        sm:align-middle
                        sm:max-w-4xl sm:w-full sm:p-6
                    "
                >
                    <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                        <button
                            type="button"
                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                            onClick={() => dispatch(closeModal())}
                        >
                            <span className="sr-only">Close</span>
                            <FontAwesomeIcon className="text-lk_red text-2xl hover:text-lk_red_hover" aria-hidden="true" icon={faTimesCircle}/>
                        </button>
                    </div>
                    <div>
                        <div className="mt-3 text-center sm:mt-16">
                            <h1 className="text-2xl sm:text-3xl lg:text-5xl">
                                Repeat This Order?
                            </h1>
                            <br/>
                            <p>This order will be placed on confirm.</p>
                            <br/><br/>
                            <div className="mt-2 grid grid-cols-2 gap-4">
                            <div>
                                <CustomButton
                                    disabled={isSubmitting}
                                    submitBool={isSubmitting}
                                    type="button"
                                    text="Confirm"
                                    className="lkbutton bg-lk_red hover:bg-lk_red_hover w-32 sm:w-96 border_radius_10"
                                    onClick={() => confirmRepeat(orderId)}
                                />
                            </div>
                            <div>
                                <CustomButton
                                    disabled={isSubmitting}
                                    type="button"
                                    text="Cancel"
                                    className="lkbutton bg-gray-400 hover:bg-gray-600 w-32 sm:w-96 border_radius_10"
                                    onClick={() => dispatch(closeModal())}
                                />
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )    
}