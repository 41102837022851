import { Formik, Form } from "formik";
import axios from "axios";
import { useHistory } from "react-router-dom";
import * as Yup from 'yup';
import CustomButton from "../../../app/common/form/CustomButton";
import CustomText from "../../../app/common/form/CustomText";
import AdminSearchForm from "../../forms/AdminSearchForm";
import AdminNav from "../../nav/AdminNav";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";

export function Account() {
    let history = useHistory();
    const { currentUser } = useSelector((state) => state.auth);

    const [cartTotal, setCartTotal] = useState([])
    const [userData, setUserData] = useState([])

    useEffect(() => {
        setCartTotal(parseFloat(window.sessionStorage.getItem("itemsInBasket")));
        getUser();
    }, []);

    

    async function getUser(){

        let activeUser = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}admin-get-user-profile`,
            headers: { 
                Authorization: `Bearer ${currentUser.token}`
             },
            data: {userUid: currentUser.id, customer_code: currentUser.isCustomer === 1 && currentUser.customer_code !== null ? currentUser.customer_code : currentUser.customer_code === null || currentUser.customer_code.split(",").length > 1 ? "None" : currentUser.customer_code},
        });

        console.log(activeUser.data[1][0])

        setUserData({name: activeUser.data[1][0].Name, email: activeUser.data[1][0].Email, branch: activeUser.data[1][0].Branch, ShortName: activeUser.data[1][0].ShortName, ShipToAddr1: activeUser.data[1][0].ShipToAddr1, ship_addr_2: activeUser.data[1][0].ShipToAddr2, ship_addr_3: activeUser.data[1][0].ShipToAddr3, ship_addr_4: activeUser.data[1][0].ShipToAddr4, ship_addr_5: activeUser.data[1][0].ShipToAddr5})
        
    }

    let initialValues = {
        name: userData.name,
        last_name : userData.last_name,
        email: userData.email,
        customer_code: userData.customer_code,
        branch: userData.branch,
        customer_short_name: userData.ShortName,
        ship_addr_1: userData.ship_addr_1,
        ship_addr_2: userData.ship_addr_2,
        ship_addr_3: userData.ship_addr_3,
        ship_addr_4: userData.ship_addr_4,
        ship_addr_5: userData.ship_addr_5,
        password: "",
        reoeat_password: ""
    };


    return (
        <div className="bg-white">

            <AdminSearchForm
                heading="Account"
                color="text-gray-500"
                header_color="bg-gradient-to-b from-gray-300"
                crumb_color="text-black"
                breadcrumbs={[
                    { name: "Home", href: "/", count: 1 },
                    { name: "Shop", href: "/shop", count: 2 },
                    { name: "Account", href: "/account", count: 3 },
                ]}
            />

            {/* <AdminNav cartTotal={cartTotal}/> */}
            <br/><br/>

            <Formik
                initialValues={initialValues}
                enableReinitialize="true"
                validationSchema={Yup.object({
                    password: Yup.string().required(),
                    repeat_password: Yup.string()
                    .oneOf([Yup.ref('password'), null], 'Passwords must match')
                    .required(),
                })}
                onSubmit={async (values, {setErrors}) => {
                    console.log(values);
                    
                    try {

                        // console.log(values.file)

                        let data = new FormData();
                        // data.append('file', values.file);
                        // data.append('first_name', values.first_name);
                        // data.append('last_name', values.last_name);
                        data.append('email', currentUser.email);
                        data.append('id', currentUser.id);
                        data.append('password', values.password);

                        // await axios.post(`${process.env.REACT_APP_API_URL}update-profile`, {headers: {Authorization: `Bearer ${currentUser.token}` }},data).then(function (response) {
                        //     console.log(response.data);
                        // });


                        await axios({
                            method: "post",
                            url: `${process.env.REACT_APP_API_URL}update-password`,
                            headers: { 
                                Authorization: `Bearer ${currentUser.token}`
                             },
                            data: data,
                        });

                        alert("Password updated");


                    } catch (error) {
                        setErrors({ message: error.response.data.error });
                    }
                }}
            >
                {({isSubmitting, errors, setFieldValue}) => (
                    <Form className="my-8 space-y-6 px-4 sm md:max-w-2xl lg:max-w-4xl xl:max-w-5xl m-auto" action="#" method="POST">

                        <div className="grid grid-cols-2 gap-4">
                            {/* <div><CustomText label="First Name" type="text" name="first_name" /></div> */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700 uppercase mb-5">
                                    <u>Name :</u>
                                </label>
                                <p>{initialValues?.name}</p>
                            </div>
                            {/* <div><CustomText label="Last Name" type="text" name="last_name" /></div> */}
                            {/* <div><CustomText label="Email" type="text" name="email" /></div> */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700 uppercase mb-5">
                                    <u>Email :</u>
                                </label>
                                <p>{initialValues?.email}</p>
                            </div>
                            {/* <div><CustomText label="Change Profile Picture" type="file" name="profile_file" onChange={(event) => {setFieldValue("file", event.currentTarget.files[0]);}}/></div> */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700 uppercase mb-5">
                                    <u>Customer Code :</u>
                                </label>
                                <p>{currentUser?.customer_code}</p>
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 uppercase mb-5">
                                    <u>Branch :</u>
                                </label>
                                <p>{initialValues?.branch}</p>
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 uppercase mb-5">
                                    <u>Customer Short Name :</u>
                                </label>
                                <p>{initialValues?.customer_short_name}</p>
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 uppercase mb-5">
                                    <u>Shipping Address 1 :</u>
                                </label>
                                <p>{initialValues?.ship_addr_1}</p>
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 uppercase mb-5">
                                    <u>Shipping Address 2 :</u>
                                </label>
                                <p>{initialValues?.ship_addr_2}</p>
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 uppercase mb-5">
                                    <u>Shipping Address 3 :</u>
                                </label>
                                <p>{initialValues?.ship_addr_3}</p>
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 uppercase mb-5">
                                    <u>Shipping Address 4 :</u>
                                </label>
                                <p>{initialValues?.ship_addr_4}</p>
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 uppercase mb-5">
                                    <u>Shipping Address 5 :</u>
                                </label>
                                <p>{initialValues?.ship_addr_5}</p>
                            </div>

                            <div>
                                <CustomText label="Update Password" type="password" name="password" />
                                {/* {userUid != 0 && (<small>Only fill in this field to update the password.</small>)} */}
                            </div>
                            
                            <div>
                                <CustomText label="Repeat Password" type="password" name="repeat_password" />
                                {/* {userUid != 0 && (<small>Only fill in this field to update the password.</small>)} */}
                            </div>
                        </div>
                        
                        
                        
                        {errors.message && <p className="text-lk_red text-lg">{errors.message}</p>}      
                        <CustomButton
                            disabled={isSubmitting}
                            submitBool={isSubmitting}
                            type="submit"
                            text="Update"
                            className="lkbutton bg-lk_red hover:bg-lk_red_hover w-full border_radius_10"
                        />

                        <br/>
                        <hr/>
                        <p>If you want to change any of your details please contact Lakato.</p>

                    </Form>                
                )}
            </Formik>
        </div>
    )    
}