import React from "react";
import { Route } from "react-router-dom";
import NavBar from "../../features/nav/NavBar/NavBar";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import HomePage from "../../../src/features/pages/HomePage";
import ContactUsPage from "../../../src/features/pages/ContactUsPage";
import Footer from "../../features/nav/Footer";
import PrivateRoute from "./PrivateRoute";

import AboutUsPage from "../../features/pages/AboutUsPage";
import ProductsPage from "../../features/pages/ProductsPage";
import ServicesPage from "../../features/pages/ServicesPage";
import OnlineOrderingPage from "../../features/pages/OnlineOrderingPage";

import WarehousingDistributionPage from "../../features/pages/services/WarehousingDistributionPage";
import WholesalePage from "../../features/pages/services/WholesalePage";
import LogisticsPage from "../../features/pages/services/LogisticsPage";
import RepackingPage from "../../features/pages/services/RepackingPage";

import HowToOpenAnAccountPage from "../../features/pages/onlineOrdering/HowToOpenAnAccountPage";
import TipsToImproveOrderingPage from "../../features/pages/onlineOrdering/TipsToImproveOrderingPage";
import HowToOrderPage from "../../features/pages/onlineOrdering/HowToOrderPage";

// import ShopPage from "../../features/pages/admin/ShopPage";
import ShopPage from "../../features/pages/admin/shopCategories/ProductPageNew";

import ModalManager from "../common/modals/ModalManager";
import ProductPage from "../../features/pages/admin/shopCategories/ProductPage";
import Promotions from "../../features/pages/admin/shopCategories/Promotions";
import Orders from "../../features/pages/admin/shopCategories/Orders";
import BackOrders from "../../features/pages/admin/shopCategories/BackOrders";
import SpecificOrder from "../../features/pages/admin/shopCategories/SpecificOrder";
import Favourites from "../../features/pages/admin/shopCategories/Favourites";
import { Account } from "../../features/pages/admin/Account";
import { Enquiries } from "../../features/pages/admin/Enquiries";
import { CustomCodes } from "../../features/pages/admin/CustomCodes";
import { CustomCodeEdit } from "../../features/pages/admin/CustomCodeEdit";
import Cart from "../../features/pages/admin/Users/Cart";
import { UserManagement } from "../../features/pages/admin/Users/UserManagement";
import { Reports } from "../../features/pages/admin/Users/Reports";
import { EditUser } from "../../features/pages/admin/Users/EditUser";
import SearchPage from "../../features/pages/admin/shopCategories/SearchPage";
import { EnquiriesHistory } from "../../features/pages/admin/Users/EnquiriesHistory";
import { EnquiriesView } from "../../features/pages/admin/Users/EnquiriesView";
import { PromotionManagement } from "../../features/pages/admin/Users/PromotionManagement";
import { EditPromotion } from "../../features/pages/admin/Users/EditPromotion";
import OrderTemplates from "../../features/pages/admin/shopCategories/OrderTemplates";
import SpecificTemplate from "../../features/pages/admin/shopCategories/SpecificTemplate";
import { SelectAccount } from "../../features/pages/admin/SelectAccount";
import ResetPassword from "../../features/forms/ResetPassword";
import SystemSettings from "../../features/pages/admin/SystemSettings";
import { AdminDashboard } from "../../features/pages/admin/AdminDashboard";
import { AdminProductCategories } from "../../features/pages/admin/productCategories/productCategories";
import { AdminProducts } from "../../features/pages/admin/products/products";
import { AdminProductTags } from "../../features/pages/admin/products/productTags";

// import AdminNavBar from "../../features/nav/NavBar/AdminNavBar";

export default function App() {
    return (
        <>
            <ModalManager />
            <NavBar />

            <Route
                path={"/"}
                render={() => (
                    <>
                        <Route exact path="/" component={HomePage} />
                        <Route path="/reset-password/:token" component={ResetPassword} />
                        <Route path="/about-us" component={AboutUsPage} />
                        <Route path="/products" component={ProductsPage} />
                        <Route exact path="/services" component={ServicesPage} />
                        <Route exact path="/services/wholesale" component={WholesalePage} />
                        <Route exact path="/services/warehousing-and-distribution" component={WarehousingDistributionPage} />
                        <Route exact path="/services/repacking" component={RepackingPage} />
                        <Route exact path="/services/logistics" component={LogisticsPage} />
                        <Route exact path="/online-ordering" component={OnlineOrderingPage} />
                        <Route exact path="/online-ordering/how-to-open-an-account" component={HowToOpenAnAccountPage} />
                        <Route exact path="/online-ordering/tips-to-improve-ordering" component={TipsToImproveOrderingPage} />
                        <Route exact path="/online-ordering/how-to-order" component={HowToOrderPage} />
                        <Route path="/contact-us" component={ContactUsPage} />
                        {/* Protected navigation */}
                        {/* <AdminNavBar /> */}
                        <PrivateRoute path="/shop" component={ShopPage} />
                        <PrivateRoute path="/shop-companion-animal-pharmaceuticals" component={ProductPage} />
                        <PrivateRoute path="/shop-poultry-pharmaceuticals" component={ProductPage} />
                        <PrivateRoute path="/shop-diagnostics" component={ProductPage} />
                        <PrivateRoute path="/shop-large-animal-pharmaceuticals" component={ProductPage} />
                        <PrivateRoute path="/shop-equine-pharmaceuticals" component={ProductPage} />
                        <PrivateRoute path="/shop-wildlife-pharmaceuticals" component={ProductPage} />
                        <PrivateRoute path="/shop-human-pharmaceuticals-used-in-veterinary-practice" component={ProductPage} />
                        <PrivateRoute path="/shop-cold-chain-biologicals" component={ProductPage} />
                        <PrivateRoute path="/shop-surgicals" component={ProductPage} />
                        <PrivateRoute path="/shop-instruments" component={ProductPage} />
                        <PrivateRoute path="/shop-disposables" component={ProductPage} />
                        <PrivateRoute path="/shop-premium-pet-foods" component={ProductPage} />
                        <PrivateRoute path="/shop-pet-accessories" component={ProductPage} />
                        <PrivateRoute path="/shop-veterinary-retail-products" component={ProductPage} />
                        <PrivateRoute path="/shop-favourites" component={Favourites} />
                        <PrivateRoute path="/promotions" component={Promotions} />
                        <PrivateRoute path="/orders" component={Orders} />
                        <PrivateRoute path="/order/:orderId" component={SpecificOrder} />
                        <PrivateRoute path="/backorders" component={BackOrders} />
                        <PrivateRoute path="/account" component={Account} />
                        <PrivateRoute path="/enquiries" component={Enquiries} />
                        <PrivateRoute path="/custom_codes" component={CustomCodes} />
                        <PrivateRoute path="/edit_custom_codes/:custom_code_id" component={CustomCodeEdit} />
                        <PrivateRoute path="/view-cart" component={Cart} />
                        <PrivateRoute path="/user-management" component={UserManagement} />
                        <PrivateRoute path="/reports" component={Reports} />
                        <PrivateRoute path="/edit/user/:userId" component={EditUser} />
                        <PrivateRoute path="/enquiries-history" component={EnquiriesHistory} />
                        <PrivateRoute path="/enquiries-view/:enquiryId" component={EnquiriesView} />
                        <PrivateRoute path="/promotion-management" component={PromotionManagement} />
                        <PrivateRoute path="/edit/promotion/:promotionID" component={EditPromotion} />
                        <PrivateRoute path="/order-templates" component={OrderTemplates} />
                        <PrivateRoute path="/template/:templateID" component={SpecificTemplate} />
                        <PrivateRoute path="/search-results" component={SearchPage} />
                        <PrivateRoute path="/select-profile" component={SelectAccount} />
                        <PrivateRoute path="/system-settings" component={SystemSettings} />
                        <PrivateRoute path="/admin-dashboard" component={AdminDashboard} />
                        <PrivateRoute path="/admin/product-categories" component={AdminProductCategories} />
                        <PrivateRoute path="/admin/products" component={AdminProducts} />
                        <PrivateRoute path="/admin/product_tags" component={AdminProductTags} />
                        {/* <Route path="/error" component={NotFound} /> */}
                    </>
                )}
            />
            <Footer />
        </>
    );
}
