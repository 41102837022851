import React from "react";
import CustomHeader from "../../app/common/elements/CustomHeader";
import OnlineOrderingCard from "../../app/common/onlineOrdering/OnlineOrderingCard";
 
export default function OnlineOrderingPage() {
    const cards = [
        {image: "/images/online-ordering/lakato_how_to_open_an_account.jpg", text: "How to open an Account", href: "/online-ordering/how-to-open-an-account"},
        {image: "/images/online-ordering/lakato_tips_to_improve_ordering.jpg", text: "Tips to improve ordering", href: "/online-ordering/tips-to-improve-ordering"},
        {image: "/images/online-ordering/lakato_how_to_order.jpg", text: "How to order", href: "/online-ordering/how-to-order"},
    ];

    return (
        <div className="bg-lk_white_backround">
            <CustomHeader heading="Online Ordering" breadcrumbs={[
                {name: "Home", href: '/', count: 1},
                {name: "Online Ordering", href: '/online-ordering', count: 2}, 
            ]}/>

            <div className="flex flex-wrap justify-center py-10 max-w-7xl m-auto">
                {cards.map((card) => (
                    <OnlineOrderingCard 
                        key={card.text}
                        image={card.image}
                        text={card.text}
                        href={card.href}
                    />
                ))}
            </div>
        </div>
    )
}
