import { Formik, Form } from "formik";
import axios from "axios";
import * as Yup from "yup";
import CustomButton from "../../../app/common/form/CustomButton";
import CustomText from "../../../app/common/form/CustomText";
import AdminSearchForm from "../../forms/AdminSearchForm";
import AdminNav from "../../nav/AdminNav";
import { useDispatch, useSelector } from "react-redux";
import CustomTextArea from "../../../app/common/form/CustomTextArea";
import { useState } from "react";
import { useEffect } from "react";
import { openModal } from "../../../app/common/modals/modalReducer";

export function Enquiries() {
    const dispatch = useDispatch();
    const { currentUser } = useSelector((state) => state.auth);
    const [cartTotal, setCartTotal] = useState([]);

    useEffect(() => {
        setCartTotal(
            parseFloat(window.sessionStorage.getItem("itemsInBasket"))
        );
    }, []);

    const initialValues = {
        first_name: currentUser.name,
        last_name: currentUser.last_name,
        email: currentUser.email,
        subject: "",
        message: "",
    };

    return (
        <div className="bg-white">
            <AdminSearchForm
                heading="Enquiries"
                color="text-gray-500"
                header_color="bg-gradient-to-b from-gray-300"
                crumb_color="text-black"
                breadcrumbs={[
                    { name: "Home", href: "/", count: 1 },
                    { name: "Shop", href: "/shop", count: 2 },
                    { name: "Enquiries", href: "/enquiries", count: 3 },
                ]}
            />

            {/* <AdminNav cartTotal={cartTotal}/> */}
            <br />
            <br />

            <Formik
                initialValues={initialValues}
                enableReinitialize="true"
                validationSchema={Yup.object({
                    email: Yup.string().required().email(),
                    subject: Yup.string().required(),
                    message: Yup.string().required(),
                })}
                onSubmit={async (values, { setErrors, resetForm }) => {
                    try {
                        await axios({
                            method: "post",
                            url: `${process.env.REACT_APP_API_URL}send-new-enquiries`,
                            headers: {
                                Authorization: `Bearer ${currentUser.token}`,
                            },
                            data: {
                                id: currentUser.id,
                                first_name: values.first_name,
                                last_name: values.last_name,
                                email: values.email,
                                subject: values.subject,
                                message: values.message,
                                customer_code: currentUser.customer_code,
                            },
                        });

                        resetForm();

                        dispatch(openModal({ modalType: "EnquirySent" }));
                    } catch (error) {
                        setErrors({ message: error.response.data.error });
                    }
                }}
            >
                {({ isSubmitting, errors }) => (
                    <Form
                        className="my-8 space-y-6 px-4 sm md:max-w-2xl lg:max-w-4xl xl:max-w-5xl m-auto"
                        action="#"
                        method="POST"
                    >
                        <div className="grid md:grid-cols-2 lg:grid-cols-2 sm:grid-cols-1 gap-4">
                            <div>
                                <CustomText
                                    label="First Name"
                                    type="text"
                                    name="first_name"
                                />
                            </div>
                            <div>
                                <CustomText
                                    label="Last Name"
                                    type="text"
                                    name="last_name"
                                />
                            </div>
                            <div>
                                <CustomText
                                    label="Email"
                                    type="text"
                                    name="email"
                                />
                            </div>
                            <div>
                                <CustomText
                                    label="Subject"
                                    type="text"
                                    name="subject"
                                />
                            </div>
                        </div>
                        {/* <div className="grid grid-cols-2 gap-4">
                            
                        </div>
                         */}
                        <CustomTextArea label="Message" name="message" />

                        <CustomButton
                            disabled={isSubmitting}
                            submitBool={isSubmitting}
                            type="submit"
                            text="Submit"
                            className="lkbutton bg-lk_red hover:bg-lk_red_hover w-full border_radius_10"
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
}
