import React from "react";
import { Link } from "react-router-dom";
import CatalogueCTA from "../../app/common/elements/CatalogueCTA";
import CustomHeader from "../../app/common/elements/CustomHeader";
import Fade from "react-reveal/Fade";

export default function AboutUsPage() {
    return (
        <div className="bg-lk_grey_background_dark">
            <CustomHeader
                image="/images/homeBannerBg.jpg"
                heading="About Us"
                breadcrumbs={[
                    { name: "Home", href: "/", count: 1 },
                    { name: "About Us", href: "/about-us", count: 2 },
                ]}
            />

            <div className="lg:flex bg-lk_grey_background_light align-middle load_in">
                <Fade left>
                    <img
                        src="/images/about1.jpg"
                        alt="company_image"
                        className="bg-white order-2 sm:order-1 lg:w-1/2 xl:max-w-7xl"
                    />
                </Fade>

                <Fade right>
                    <div className="py-4 sm:py-12 lg:py-4 order-1 sm:order-2 lg:w-1/2 lg:p-12 xl:p-24 my-auto max-w-xs px-4 sm:max-w-2xl lg:max-w-5xl mx-auto ">
                        <p>
                            Lakato, founded more than 32 years ago, is one of
                            South Africa’s leading Veterinary Wholesalers,
                            supplying the broadest range of Veterinary products
                            possible to; Veterinary Practices, the Veterinary
                            profession, Veterinary registered Welfare
                            organisations, Veterinarian owned retail outlets and
                            Professional Pet shop retailers throughout South
                            Africa. These products include the likes of;
                            Companion Animal Pharmaceuticals, Large Animal
                            Pharmaceuticals, Equine, Poultry, Avian, Swine and
                            Wildlife Pharmaceuticals, Surgicals, Diagnostics,
                            Instruments, Disposables, Cold Chain Biologicals,
                            Human Pharmaceuticals used in Veterinary Practice,
                            Premium Pet Foods and Treats, Pet accessories and
                            Veterinary Retail Products.
                        </p>
                    </div>
                </Fade>
            </div>

            <div className="py-4 px-4 sm:px-0 sm:py-12 lg:py-12 lg:px-32 relative lg:h-cta_height max-w-7xl mx-auto">
                <Fade up>
                    <p
                        className="
                        my-4 lg:my-0 mx-auto 
                        text-lk_grey_two text-sm sm:text-md xl:text-lg shadow-card_border border_radius_10 z-10 align-center whitespace-normal bg-white
                        py-12 px-12 lg:p-32 xl:p-24 
                        lg:absolute top-1/3 left-10 lg:left-6 xl:left-36
                        md:w-contact_card lg:w-about_us_card"
                    >
                        Having branches located in major centres of South
                        Africa, namely, Johannesburg in Gauteng, Cape Town in
                        the Western Cape, Pietermaritzburg in KwaZulu Natal and
                        Gqeberha in the Eastern Cape, Lakato can efficiently
                        provide a service to its National customer base and can
                        truly claim to be South Africa’s leading National
                        Veterinary Wholesaler.
                    </p>
                    <img
                        src="/images/lakato_about_us_cape_town.jpg"
                        alt="company_image"
                        className="my-4 lg:my-0 shadow-card_border border_radius_10 lg:absolute lg:right-10 xl:right-36 bg-white z-0 md:w-contact_card lg:w-about_us_card lg:h-about_us_card mx-auto"
                    />
                </Fade>
            </div>

            <div className="lg:flex bg-lk_grey_background_light align-middle xl:mb-40">
                <Fade left>
                    <div className="py-4 sm:py-12 lg:py-4 order-2 sm:order-1 lg:w-1/2 lg:p-12 xl:p-24 my-auto max-w-xs px-4 sm:max-w-2xl lg:max-w-5xl mx-auto ">
                        <p>
                            Lakato’s current owners and director group have been
                            at the helm of Lakato for the last 25 years.
                            Lakato’s management team is committed to ethical
                            business practice and are passionate about their
                            business, their customer base and the market in
                            which Lakato operates. Lakato’s biggest asset is its
                            staff who are committed to service excellence. Staff
                            in each of its branches are highly skilled and
                            knowledgeable about the products which Lakato
                            distributes. Lakato, through its owners, staff and
                            management strive to provide a superlative service
                            and endeavours to establish a special relationship
                            between itself and its clients.
                        </p>
                    </div>
                </Fade>
                <Fade right>
                    <img
                        src="/images/about3.jpg"
                        alt="company_image"
                        className="bg-white order-2 sm:order-1 lg:w-1/2"
                    />
                </Fade>
            </div>

            <div className="py-12 px-4 sm:px-0 lg:px-20 bg-lk_grey_background_extra_light relative lg:flex xl:block xl:h-cta_medium_height">
                <Fade up>
                    <div className="bg-white my-4 lg:my-0 xl:w-1/3 lg:mr-2 p-8 shadow-card_border border_radius_10 xl:absolute xl:right-1/2 xl:-top-10 xl:max-w-3xl">
                        <img
                            src="/images/lakatoSAVCLogo.jpg"
                            alt="lakatoSAVCLogo"
                        />
                        <p className="text-lg sm:text-2xl lg:text-4xl text-lk_grey_two lg:w-80 py-6">
                            South African Veterinary Council
                        </p>
                        <p className="pb-8 text-sm sm:text-md xl:text-lg">
                            The South African Veterinary Council is the
                            regulatory body for the veterinary and
                            para-veterinary professions in South Africa and has
                            a statutory duty to determine scientific and ethical
                            standards of professional conduct and education.
                        </p>
                        <Link
                            className={
                                "bg-lk_grey_one text-white hover:bg-lk_grey_two uppercase border_radius_10 py-3 px-4 lg:px-24 text-base font-semibold block text-center "
                            }
                            as="a"
                            to="/savc"
                        >
                            Find out more
                        </Link>
                    </div>
                </Fade>
                <Fade up>
                    <div className="bg-white my-4 lg:my-0 xl:w-1/3 lg:ml-2 p-8 shadow-card_border border_radius_10 xl:absolute xl:left-1/2 xl:-top-10 xl:max-w-3xl">
                        <img
                            src="/images/lakatoSAVALogo.jpg"
                            alt="lakatoSAVALogo"
                        />
                        <p className="text-lg sm:text-2xl lg:text-4xl text-lk_grey_two py-6">
                            South African Veterinary Association
                        </p>
                        <p className="pb-8 text-sm sm:text-md xl:text-lg">
                            The South African Veterinary Association (SAVA) is
                            an association for registered members of the
                            veterinary profession and represents the interests
                            of its members, and in so doing, the interests of
                            animals and the public.
                        </p>
                        <Link
                            className={
                                "bg-lk_grey_one text-white hover:bg-lk_grey_two uppercase border_radius_10 py-3 px-4 lg:px-24 text-base font-semibold block text-center"
                            }
                            as="a"
                            to="/savc"
                        >
                            Find out more
                        </Link>
                    </div>
                </Fade>
            </div>

            <CatalogueCTA />
        </div>
    );
}
