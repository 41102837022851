import { faHeart } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import AdminSearchForm from "../../../forms/AdminSearchForm";
import AdminNav from "../../../nav/AdminNav";
import { useTable, usePagination, useSortBy } from 'react-table'
import Select from 'react-select'
import { Link, useHistory } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";


export default function OrderTemplates(props) {
  
    let history = useHistory();
    const { currentUser } = useSelector((state) => state.auth);

    // const [cartTotal, setCartTotal] = useState([])
    const [templates, setTemplates] = useState([])
    // const [pageNumber, setPageNumber] = useState(0);
    // const [recordCount, setRecordCount] = useState("0");
    // const [sortBy, setSortBy] = useState("OrderDate");
    // const [sortDirection, setSortDirection] = useState("asc");

    if(currentUser.multi_account === true && (currentUser.customer_code == null || currentUser.customer_code == "None" || currentUser.customer_code.split(",").length > 1) ){
        window.location.replace('/select-profile')
    }

    useEffect(() => {
        // setCartTotal(parseFloat(window.sessionStorage.getItem("itemsInBasket")));
        getOrders();
    }, [])

    async function getOrders(){
        console.log("TEST")
        let templates = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}all-templates`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                id: currentUser.id,
            },
        });

        console.log(currentUser.id)

      console.log(templates.data)

      let orderList = templates.data;

      // console.log(orderList)

      let allOrders = [];

      await Object.keys(orderList).forEach(function (key) {
        
        allOrders.push({ col0: orderList[key].id, col1: orderList[key].template_name, col2: orderList[key].SalesOrder, col6: "R "+orderList[key].orderCost})
      })

      setTemplates(allOrders)
    }

    const data = React.useMemo(() => templates, [templates])

      const columns = React.useMemo(
        () => [
          {
            Header: () => (
              <div
                style={{
                  textAlign:"left"
                }}
              >Template Name</div>),
            accessor: 'col1',
          },
         
        ],
        []
      )

      const tableInstance = useTable({ columns, data, manualPagination:true }, useSortBy, usePagination)

      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        prepareRow,
        pageIndex,
        pageOptions,
        gotoPage,
        pageCount,
        pageSize,
        setPageSize,
      } = tableInstance

    //   async function nextPageNavigate(pageNumber){

    //     let pageNo = parseFloat(pageNumber)
    //     let newNo = pageNo + 1
        
    //     setPageNumber(newNo)

    //     getOrders(newNo);
    // }

    // async function previousPageNavigate(pageNumber){

    //     let pageNo = parseFloat(pageNumber)
    //     let newNo = pageNo - 1
        
    //     setPageNumber(newNo)

    //     getOrders(newNo);
    // }

    return(
        <div className="bg-gray-50">
            <AdminSearchForm
                heading="Order Templates"
                color="text-gray-500"
                header_color="bg-gradient-to-b from-gray-300"
                crumb_color="text-black"
                breadcrumbs={[
                    { name: "Home", href: "/", count: 1 },
                    { name: "Order Templates", href: "/order-templates", count: 2 },
                ]}
            />

            {/* <AdminNav cartTotal={cartTotal}/> */}
            <br/><br/>

            <div className="container mx-auto">

                <table {...getTableProps()} className="mb-10 w-full" style={{borderCollapse: "separate", borderSpacing: "0 1em"}}>
                    <thead>
                    {
                    headerGroups.map((headerGroup, index) => (
                        
                        <tr {...headerGroup.getHeaderGroupProps()} className="bg-gray-200" key={index}>
                        {
                        headerGroup.headers.map(column => (
                            
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className="px-2 py-5 font-light text-sm">
                            {column.render('Header')}
                            </th>
                        ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {
                    page.map(row => {
                        
                        prepareRow(row)
                        return (
                        

                            <tr {...row.getRowProps()} className="bg-white mt-5 shadow-lg cursor-pointer" onClick={() => history.push("/template/"+row.original.col0)}>
                                {
                                row.cells.map((cell, index) => {
                                
                                return (
                                    <td {...cell.getCellProps()} style={{borderRight: "1px solid #EFEFEF", textAlign: "left"}} className="mx-auto text-center relative px-2 py-2" key={index}>
                                        <p className="text-left px-5 py-5">{cell.render('Cell')}</p>
                                    </td>
                                )
                                })}
                            </tr>
                        )
                    })}
                    </tbody>
                </table>

                <nav
                    className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 bg-gray-50"
                    aria-label="Pagination"
                >
                    {/* <div className="hidden sm:block">
                        <p className="text-sm text-gray-700">
                            Page <span className="font-medium">{parseFloat(pageNumber) + 1}</span> of{" "}
                            <span className="font-medium">{Math.ceil(parseFloat(recordCount) /10)}</span>
                        </p>
                        <p className="text-sm text-gray-700">
                            Showing <span className="font-medium">{parseFloat(pageNumber) === 0 ? "1" : (parseFloat(pageNumber) * 10) +1}</span> to{" "}
                            <span className="font-medium">{parseFloat(pageNumber) === 0 ? "10" : (parseFloat(pageNumber) * 10) +10 > recordCount ? recordCount : (parseFloat(pageNumber) * 10) +10}</span> of{" "}
                            <span className="font-medium">{recordCount}</span> results
                        </p>
                        
                    </div> */}
                    {/* <div className="flex-1 flex justify-between sm:justify-end">
                        <button
                            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                            onClick={() => previousPageNavigate(pageNumber)}
                            disabled={pageNumber === 0}
                        >
                            Previous
                        </button>
                        <button
                            onClick={() => nextPageNavigate(pageNumber)}
                            disabled={(parseFloat(pageNumber) * 10) +10 > recordCount}
                            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                        >
                            Next
                        </button>
                    </div> */}
                </nav>

            </div>

        </div>
    )
}