import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";

export default function CustomButton({ submitBool, text, disabled, ...props }) {
    return (
        <button
            {...props}
            disabled={submitBool ? submitBool : disabled}
            
        >
            {submitBool ? (
                <>
                    <FontAwesomeIcon icon={faSpinnerThird} className="animate-spin text-xl" />
                </>
            ) : (
                <>
                    <span className="truncate">{text}</span>
                </>
            )}
        </button>
    );
}
