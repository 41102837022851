import axios from "axios";

export const GET_CART_TOTAL = "GET_CART_TOTAL";

export const getCartTotal = (currentUser) => async (dispatch) => {
    if (!currentUser) return;

    try {
        const response = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}total-items-in-cart`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                id: currentUser.id,
                customer_code: currentUser.customer_code,
            },
        });

        dispatch({
            type: GET_CART_TOTAL,
            payload: response.data || 0,
        });
    } catch (error) {
        console.error("An error occurred while fetching the cart total:", error);
    }
};
