import React from "react";
import CustomHeader from "../../../app/common/elements/CustomHeader";
import CustomStep from "../../../app/common/elements/CustomStep";

export default function HowToOpenAnAccountPage() {
    const steps = [
        {text: "For assistance with your registration as an on-line customer, please contact our Debtors department on", footnote: "(011) 624-1524", count: "01"},
        {text: "Upon registration, an on-line user name and password will be issued. If desired, or needed, you will have the flexibility to change this password.", count: "02"},
        {text: "Please note that we will use information which is documented on your existing credit application with Lakato to notify you of any details pertaining to your on-line transactions. In this regard, it is your responsibility to ensure that your credit application has your correct and current details.", count: "03"},
        {text: "Upon placing your on-line order, a confirmation will be sent via e-mail to you. This will detail time and date of the order being received by ourselves as well as order content and regrets.", count: "04"},
        {text: "To avoid late delivery, please ensure that your on-line order is placed timeously. Contact your local branch to confirm the cut off times with regards to placing of your order.", count: "05"},
        {text: "For our on-line customers who are in outlying areas our existing minimum order conditions apply.", count: "06"},
        {text: "Settlement discount will apply to your on line purchases.", count: "07"},
    ];

    return (
        <div className="bg-lk_white_backround">
            <CustomHeader heading="How To Open An Account" breadcrumbs={[
                {name: "Home", href: '/', count: 1},
                {name: "Online Ordering", href: '/online-ordering', count: 2}, 
                {name: "How To Open An Account", href: '/online-ordering/how-to-open-an-account', count: 3}, 
            ]}/>
            
            <p className="text-lk_grey_one px-4 text-2xl sm:text-3xl lg:text-5xl mx-auto my-12 text-center max-w-2xl lg:max-w-4xl xl:max-w-5xl">To be able to use our on-line ordering system, a customer must have an existing account with Lakato.</p>

            <div className="lg:flex lg:flex-wrap justify-center 2xl:justify-start xl:max-w-7xl py-10 m-auto">
                {steps.map((step) => (
                    <CustomStep 
                        key={step.text}
                        text={step.text}
                        count={step.count}
                        footnote={step.footnote}
                    />
                ))}
            </div>
        </div>
    )
}
