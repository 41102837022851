import React from "react";
import CatalogueCTA from "../../../app/common/elements/CatalogueCTA";
import CustomHeader from "../../../app/common/elements/CustomHeader";
// import ServicesImageCard from "../../../app/common/elements/ServicesImageCard";
import IconCard from "../../../app/common/elements/IconCard";
import WholesaleImageCard from "../../../app/common/elements/WholesaleImageCard";

export default function WholesalePage() {
    const services = [
        {
            image: "/images/icons/animal.png",
            text: "Companion Animal Pharmaceuticals",
            count: "01",
        },
        {
            image: "/images/icons/cold_chain.png",
            text: "Cold chain Biologicals",
            count: "02",
        },
        {
            image: "/images/icons/premium_pet_food.png",
            text: "Premium Pet Foods",
            count: "03",
        },
        {
            image: "/images/icons/large_animal.png",
            text: "Large Animal Pharmaceuticals",
            count: "04",
        },
        {
            image: "/images/icons/veterinary.png",
            text: "Veterinary retail products",
            count: "05",
        },
        {
            image: "/images/icons/poultry.png",
            text: "Poultry Pharmaceuticals",
            count: "06",
        },
        {
            image: "/images/icons/pet_accessories.png",
            text: "Pet Toys and Accessories",
            count: "07",
        },

        {
            image: "/images/icons/exotic_animal.png",
            text: "Exotic Animal Pharmaceuticals",
            count: "08",
        },
        {
            image: "/images/icons/pharmaceuticals.png",
            text: "Human products used in Veterinary practice",
            count: "09",
        },
        {
            image: "/images/icons/disposable.png",
            text: "Disposables",
            count: "10",
        },
        {
            image: "/images/icons/diagnostics.png",
            text: "Diagnostics",
            count: "11",
        },
        { image: "/images/icons/surgical.png", text: "Surgicals", count: "12" },
        {
            image: "/images/icons/instrument.png",
            text: "Instruments",
            count: "13",
        },
        {
            image: "/images/icons/equine.png",
            text: "Equine Pharmaceuticals",
            count: "14",
        },
        {
            image: "/images/icons/exotic_premium_foods.png",
            text: "Exotic Premium Foods, Toys and Accessories",
            count: "15",
        },
        {
            image: "/images/icons/wildlife.png",
            text: "Game Pharmaceuticals",
            count: "16",
        },
        {
            image: "/images/icons/promotional.png",
            text: "Promotional Items on behalf of its suppliers",
            count: "17",
        },
    ];
    return (
        <div className="bg-lk_white_backround">
            <CustomHeader
                heading="Wholesale"
                subHeading="Services"
                breadcrumbs={[
                    { name: "Home", href: "/", count: 1 },
                    { name: "Services", href: "/services", count: 2 },
                    {
                        name: "Wholesale",
                        href: "/services/wholesale",
                        count: 3,
                    },
                ]}
            />

            <p className="text-lk_grey_one text-2xl sm:text-3xl lg:text-5xl mx-auto my-12 text-center px-4 max-w-xs sm:max-w-2xl lg:max-w-7xl">
                Lakato (Pty) Ltd is the leading Veterinary Wholesaler in
                Southern Africa.
            </p>
            <p className="mx-auto text-center mb-12 text-lg px-4 max-w-xs sm:max-w-2xl lg:max-w-7xl">
                Having branches located in 4 provinces in South Africa namely;
                Johannesburg in Gauteng, Cape Town in the Western Cape,
                Pietermartizburg in KwaZulu Natal and Gqeberha in the Eastern
                Cape.
            </p>

            <WholesaleImageCard
                image="/images/services/lakato_wholesale_isle.jpg" imageAlt="Lakato Wholesale Isle"
                text={"Lakato offers a daily delivery service to those customers who are in close proximity to one of its branches. Delivery is between a next day to 72 hrs basis to those clients in the more outlying areas or those who are beyond the borders of South Africa."}
                imageAlign="1"
            />

            <WholesaleImageCard
                image="/images/services/lakato_wholesale_truck.jpg" imageAlt="Lakato Wholesale Truck"
                text={"Lakato carries an extensive number of lines including those which are either used in Veterinary Practice or retailed though professional Vet and Pet businesses. It offers its customer base access to the broadest range of products in more than adequate stock levels at competitive prices."}
                imageAlign="2"
            />

            <WholesaleImageCard
                image="/images/services/lakato_wholesale_shelf.jpg" imageAlt="Lakato Wholesale Truck"
                text={"It prides itself in the nature, diversity, quantity, and volume of stock lines which it holds. In essence, Lakato carries or has access to all products which are used and required by its broad customer base."}
                imageAlign="3"
            />

            <div className="mt-24 pb-16 lg:px-20 bg-lk_grey_background_dark_d9">
                <p className="text-lg sm:text-2xl lg:text-4xl text-center py-12">
                    These include the likes of:
                </p>
                <div className="flex flex-wrap justify-center lg:justify-start mx-auto max-w-7xl">
                    {services.map((service) => (
                        <IconCard
                            key={service.text}
                            image={service.image}
                            count={service.count}
                            text={service.text}
                        />
                    ))}
                </div>
            </div>

            <CatalogueCTA />
        </div>
    );
}
