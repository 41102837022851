import React from "react";
import { Link } from "react-router-dom";

export default function CustomHeader({home, image, heading, subHeading, breadcrumbs}) {
    var backgroundHeader;

    if (image) {
        backgroundHeader = {
            background: `url('${image}') no-repeat center center`,
            backgroundSize: "cover",
        }
    }
    
    return (
        <div className="fade_in h-96 flex items-center justify-center header_background relative shadow-sm" style={backgroundHeader}>
            {home ? (
                <div className="max-w-6xl w-full mx-auto">
                    <h2 className="text-4xl sm:text-5xl lg:text-6xl xl:text-7xl text-lk_grey_two max-w-2xl text-center">
                        Southern Africa’s{" "}
                        <span className="font-bold text-lk_red">Leading</span>{" "}
                        Veterinary Wholesaler
                    </h2>
                </div>
            ) : (
                <>
                    <div className="mx-auto max-w-7xl">
                        {subHeading && (
                            <h3 className="-mt-8 text-2xl sm:text-3xl text-lk_grey_two text-center text-opacity-50">
                                {subHeading}
                            </h3>
                        )}
                        <h2 className="text-4xl sm:text-5xl lg:text-6xl xl:text-7xl text-lk_grey_two text-center">
                            {heading}
                        </h2>
                    </div>
                    <div className="text-xs text-lk_grey_two absolute bottom-5 font-bold uppercase px-8 sm:px-0 lg:max-w-7xl">
                        {breadcrumbs.map((item) => (
                            <span key={item.name}>
                                {item.count > 1 && (
                                    <span>{' '}{'>'}{' '}</span>
                                )}
                                <Link
                                    key={item.name}
                                    as="a"
                                    to={item.href}
                                    aria-current={item.current ? "page" : undefined}
                                    className="hover:text-lk_dark_grey_button_hover"
                                >
                                    {item.name}
                                </Link>
                            </span>
                        ))}
                    </div>
                </>
            )}
        </div>
    )
}