import React, { Fragment, useState } from 'react'
// import { useHistory } from "react-router-dom";
import { closeModal } from "../../app/common/modals/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCheckCircle, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { Dialog, Transition } from '@headlessui/react';
import { faExclamation, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import CustomButton from '../../app/common/form/CustomButton';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from "axios";
import CustomText from "../../app/common/form/CustomText";

export default function TemplateName(props) {
    // let history = useHistory();
    const dispatch = useDispatch();
    const [open] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { currentUser } = useSelector((state) => state.auth);

    // console.log(props.errors)

    let orderItems = props.orderItems;

    console.log(orderItems)




    return (
        <div className="fixed z-50 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                    className="fixed inset-0 transition ease-in duration-300"
                    aria-hidden="true"
                >
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                </div>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                    &#8203;
                </span>
                
                <div 
                    className="
                        inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 
                        overflow-hidden shadow-xl transform transition-all 
                        sm:align-middle
                        sm:max-w-4xl sm:w-full sm:p-6
                    "
                >
                    <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                        <button
                            type="button"
                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                            onClick={() => dispatch(closeModal())}
                        >
                            <span className="sr-only">Close</span>
                            <FontAwesomeIcon className="text-lk_red text-2xl hover:text-lk_red_hover" aria-hidden="true" icon={faTimesCircle}/>
                        </button>
                    </div>
                    <div>
                        <div className="mt-3 text-center sm:mt-16">
                            <h1 className="text-2xl sm:text-3xl lg:text-5xl">
                                Create a Template
                            </h1>
                            <div className="mt-2 sm:px-32 mt-12 mb-5">

                            <Formik
                            initialValues={{ template_name: ''}}
                                validate={values => {
                                    const errors = {};
                                    if (!values.template_name) {
                                        errors.template_name = 'Required';
                                    }
                                    return errors;
                                }}
                                onSubmit={async (values, { setSubmitting }) => {

                                    console.log(orderItems)
                                    await axios({
                                        method: "post",
                                        url: `${process.env.REACT_APP_API_URL}create-order-template`,
                                        headers: { Authorization: `Bearer ${currentUser.token}` },
                                        data: {
                                            orderItems: orderItems,
                                            template_name: values.template_name,
                                            user_id: currentUser.id,
                                        },
                                    });

                                    dispatch(closeModal());
                                }}
                                >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    /* and other goodies */
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <CustomText label="Template Name" type="text" name="template_name" />

                                        <div className="mt-2 grid grid-cols-2 gap-4">
                                            <div>
                                                <CustomButton
                                                    disabled={isSubmitting}
                                                    submitBool={isSubmitting}
                                                    type="submit"
                                                    text="Confirm"
                                                    className="lkbutton bg-lk_red hover:bg-lk_red_hover w-32 sm:w-52 border_radius_10"
                                                />
                                            </div>
                                            <div>
                                                <CustomButton
                                                    disabled={isSubmitting}
                                                    type="button"
                                                    text="Cancel"
                                                    className="lkbutton bg-gray-400 hover:bg-gray-600 w-32 sm:w-52 border_radius_10"
                                                    onClick={() => dispatch(closeModal())}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                )}
                                </Formik>

                                

                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}