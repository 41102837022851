import { GET_CART_TOTAL } from "./cartActions";

const initialState = {
    cartTotal: 0,
};

export const cartReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CART_TOTAL:
            return {
                ...state,
                cartTotal: action.payload,
            };
        default:
            return state;
    }
};
