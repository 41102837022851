import React from "react";
import { useDispatch } from "react-redux";
import Fade  from 'react-reveal/Fade';
import { openModal } from "../../common/modals/modalReducer";

export default function IconCard({ image, count, text }) {
    const dispatch = useDispatch();

    return (
        <Fade left>
            <div className="bg-white border_radius_10 mx-2 lg:mx-4 my-4 px-10 pt-6 pb-12 w-screen lg:w-96 min-h-72 shadow-card_border text-center">
                <button onClick={() => dispatch(openModal({modalType: "ModalLogin"}))}>
                    <img src={image} alt={text} className="mx-auto"/>
                    <p className="text-4xl text-lk_grey_one pt-4">{count}</p>
                    <p className="text-xl text-lk_grey_two pt-2">{text}</p>
                </button>
            </div>
        </Fade>
    );
}
