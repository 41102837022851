import { Formik, Form } from "formik";
import axios from "axios";
import { useHistory } from "react-router-dom";
import * as Yup from 'yup';
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import CustomButton from "../../../../app/common/form/CustomButton";
import CustomText from "../../../../app/common/form/CustomText";
import AdminSearchForm from "../../../forms/AdminSearchForm";
import AdminNav from "../../../nav/AdminNav";
import Select from 'react-select'

export function EnquiriesView(props) {
    let history = useHistory();
    const { currentUser } = useSelector((state) => state.auth);

    let enquiryId = props.match.params.enquiryId

    const [cartTotal, setCartTotal] = useState([])
    const [enquiry, setEnquiry] = useState([])

    useEffect(() => {
        setCartTotal(parseFloat(window.sessionStorage.getItem("itemsInBasket")));
        getEnquiry();
    }, []);

    async function getEnquiry(){
        let thisEnquiry = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}get-enquiry`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                enquiryId: enquiryId
            },
        });
    
        setEnquiry(thisEnquiry.data)
    }

    

    return (
        <>
            <div className="bg-white">

                <AdminSearchForm
                    heading={"View Enquiry"}
                    color="text-gray-500"
                    header_color="bg-gradient-to-b from-gray-300"
                    crumb_color="text-black"
                    breadcrumbs={[
                        { name: "Home", href: "/", count: 1 },
                        { name: "Enquiries History", href: "/enquiries-history", count: 2 },
                        { name: "View Enquiry", href: "/enquiries-view/"+enquiryId, count: 3 },
                    ]}
                />

                {/* <AdminNav cartTotal={cartTotal}/> */}
                <br/><br/>

            </div>

            <div className="container mx-auto flex grid justify-items-center">
                <div className="border-2 p-8 w-8/12">
                    <p className="text-3xl">Enquiry #{enquiryId}</p>
                    <br/><br/>
                    <p><span className="font-bold">Name:</span> {enquiry.first_name} {enquiry.last_name}</p>
                    <p><span className="font-bold">Email Address:</span> {enquiry.email}</p>
                    <br/>
                    <p><span className="font-bold">Subject:</span> {enquiry.subject}</p>
                    <br/><br/>
                    <p className="font-bold">Message:</p>
                    <p>{enquiry.message}</p>
                </div>
            </div>
        </>
    )    
}