import React from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import AdminSearchBar from "../forms/AdminSearchBar";
import { openModal } from "../../app/common/modals/modalReducer";
import { useDispatch } from "react-redux";
import { current } from "@reduxjs/toolkit";

export default function AdminNav(props) {
    const dispatch = useDispatch();

    const { currentUser } = useSelector((state) => state.auth);

    const location = useLocation();

    // let image = `${process.env.REACT_APP_URL}/${currentUser.profile_image}`
    // let imgPlaceholder = `${process.env.REACT_APP_URL}/images/placeholder.png`

    // let cartTotal = props.cartTotal

    // async function signout(){
    //     await axios({
    //         method: "post",
    //         url: `${process.env.REACT_APP_API_URL}logout`,
    //         headers: { Authorization: `Bearer ${currentUser.token}` },
    //     })
    //     .then((res) => {
    //         dispatch(signOutUser());
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //     });
    // }

    let activeClass =
        "inline-block relative py-2 px-2 w-full text-sm font-medium text-center text-white bg-gray-500 hover:text-gray-700 hover:bg-gray-50 focus:ring-4 focus:ring-blue-300 focus:z-20";
    let inActiveClass =
        "inline-block relative py-2 px-2 w-full text-sm font-medium text-center text-gray-500 bg-white rounded-r-lg hover:text-gray-700 hover:bg-gray-50 focus:ring-4 focus:ring-blue-300 focus:z-20";

    return (
        <div className="container mx-auto ">
            <ul className="hidden rounded-lg divide-x divide-gray-200 shadow-lg sm:flex border">
                {/* <li className="w-full">
                    <Link
                        className="inline-block relative py-2 px-2 w-full text-sm font-medium text-center bg-white rounded-l-lg focus:ring-4 focus:ring-blue-300 focus:z-20 active dark:bg-gray-700 dark:text-white"
                        aria-current="page"
                        as="a"
                        to="/account"
                    >
                        {currentUser.profile_image !== null ? <img className="inline-block h-9 w-9 rounded-full " src={image} alt=""/> : <img className="inline-block h-9 w-9 rounded-full " src={imgPlaceholder} alt=""/>}
                        
                        &nbsp; &nbsp; Account
                    </Link>
                </li> */}
                <li className="w-full">
                    <Link
                        className={
                            location.pathname == "/"
                                ? activeClass
                                : inActiveClass
                        }
                        as="a"
                        to="/"
                    >
                        <FontAwesomeIcon icon={faHome} className="mr-2" />
                        Home
                    </Link>
                </li>
                <li className="w-full">
                    <Link
                        className={
                            location.pathname == "/shop" ||
                            location.pathname == "/search-results"
                                ? activeClass
                                : inActiveClass
                        }
                        as="a"
                        to="/shop"
                    >
                        Shop
                    </Link>
                </li>
                <li className="w-full">
                    <Link
                        className={
                            location.pathname == "/shop-favourites"
                                ? activeClass
                                : inActiveClass
                        }
                        as="a"
                        to="/shop-favourites"
                    >
                        Favourites
                    </Link>
                </li>
                <li className="w-full">
                    <Link
                        className={
                            location.pathname == "/order-templates"
                                ? activeClass
                                : inActiveClass
                        }
                        as="a"
                        to="/order-templates"
                    >
                        Order Templates
                    </Link>
                </li>
                <li className="w-full">
                    <Link
                        className={
                            location.pathname == "/promotions"
                                ? activeClass
                                : inActiveClass
                        }
                        as="a"
                        to="/promotions"
                    >
                        Promotions
                    </Link>
                </li>
                <li className="w-full">
                    <Link
                        className={
                            location.pathname == "/orders"
                                ? activeClass
                                : inActiveClass
                        }
                        as="a"
                        to="/orders"
                    >
                        Order History
                    </Link>
                </li>
                {/* <li className="w-full">
                    <Link
                        className="inline-block relative py-2 px-2 w-full text-sm font-medium text-center text-gray-500 bg-white rounded-r-lg hover:text-gray-700 hover:bg-gray-50 focus:ring-4 focus:ring-blue-300 focus:z-20 dark:text-gray-400 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700 dark:text-white"
                        as="a"
                        to="#"
                    >Payment</Link>
                </li> */}
                <li className="w-full">
                    <Link
                        className={
                            location.pathname == "/enquiries"
                                ? activeClass
                                : inActiveClass
                        }
                        as="a"
                        to="/enquiries"
                    >
                        Enquiries
                    </Link>
                </li>
                <li className="w-full">
                    <Link
                        className={
                            location.pathname == "/custom_codes"
                                ? activeClass
                                : inActiveClass
                        }
                        as="a"
                        to="/custom_codes"
                    >
                        Custom Codes
                    </Link>
                </li>

                {currentUser.price_list === 1 &&
                    !currentUser.customer_code?.includes(",") && (
                        <li className="w-full">
                            <button
                                className={inActiveClass}
                                onClick={() =>
                                    dispatch(
                                        openModal({
                                            modalType: "PriceList",
                                        })
                                    )
                                }
                            >
                                Price List
                            </button>
                        </li>
                    )}

                {currentUser.isAdmin === 1 &&
                    currentUser.isSales === 0 &&
                    currentUser.isLakato === 0 && (
                        <>
                            <li className="w-full">
                                <Link
                                    className={
                                        location.pathname ==
                                        "/promotion-management"
                                            ? activeClass
                                            : inActiveClass
                                    }
                                    as="a"
                                    to="/promotion-management"
                                >
                                    Promotion Manager
                                </Link>
                            </li>
                            <li className="w-full">
                                <Link
                                    className={
                                        location.pathname ==
                                        "/enquiries-history"
                                            ? activeClass
                                            : inActiveClass
                                    }
                                    as="a"
                                    to="/enquiries-history"
                                >
                                    Enquiries History
                                </Link>
                            </li>
                            {/* <li className="w-full">
                                <Link
                                    className={
                                        location.pathname == "/user-management"
                                            ? activeClass
                                            : inActiveClass
                                    }
                                    as="a"
                                    to="/user-management"
                                >
                                    User Manager
                                </Link>
                            </li> */}
                            <li className="w-full">
                                <Link
                                    className={
                                        location.pathname == "/reports"
                                            ? activeClass
                                            : inActiveClass
                                    }
                                    as="a"
                                    to="/reports"
                                >
                                    Reports
                                </Link>
                            </li>
                            <li className="w-full">
                                <Link
                                    className={
                                        location.pathname == "/admin-dashboard"
                                            ? activeClass
                                            : inActiveClass
                                    }
                                    as="a"
                                    to="/admin-dashboard"
                                >
                                    Admin
                                </Link>
                            </li>
                        </>
                    )}
                {/* {currentUser && currentUser.main_admin === true ? 
                    <>
                        <li className="w-full">
                            <Link
                                className = {location.pathname == "/user-management" ? activeClass : inActiveClass}
                                as="a"
                                to="/user-management"
                            >User Manager</Link>
                        </li>
                    </>
                : "" } */}
                {/* <li className="w-full">
                    <div
                        className="inline-block relative py-2 px-2 w-full text-sm font-medium text-center text-gray-500 bg-white rounded-r-lg hover:text-gray-700 hover:bg-gray-50 focus:ring-4 focus:ring-blue-300 focus:z-20 dark:text-gray-400 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700 dark:text-white cursor-pointer" onClick={() => signout()}
                    >Logout</div>
                </li> */}
                {/* <li className="w-full">
                    <Link
                        className="inline-block relative py-2.5 px-2.5 w-full text-sm font-medium text-center text-white bg-gray-400 rounded-r-lg hover:text-gray-700 hover:bg-gray-50 focus:ring-4 focus:ring-blue-300 focus:z-20 dark:text-gray-400 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700 dark:text-white flex items-center rounded-md"
                        as="a"
                        to="/view-cart"
                    >
                        <div className="h-8 w-8 rounded-full bg-white mr-4">
                            {cartTotal > 0 ? (
                                <div className="h-5 w-5 rounded-full bg-lk_red ml-5 -mt-2.5">
                                    <span className="text-xs">{cartTotal}</span>
                                </div>
                            ) : <div className="h-5 w-5 rounded-full ml-5 -mt-2.5"></div>}
                        
                            <FontAwesomeIcon 
                                className={"text-black cursor-pointer text-base"}
                                icon={faShoppingCart}
                            /> 
                        </div>
                    View Cart</Link>
                </li> */}
            </ul>

            {location.pathname !== "/search-results" && <AdminSearchBar />}
        </div>
    );
}
