import React from "react";
import { Link } from "react-router-dom";
import Fade  from 'react-reveal/Fade';

export default function OnlineOrderingCard({ image, text, href }) {
    return (
        <Fade left>
            <Link
                as="a"
                to={href}
            >
                <div className="border_radius_10 mx-4 my-4 w-72 sm:w-auto shadow-card_border h-80 md:h-card_432px">
                    <img src={image} alt={text} />
                    <div className="p-10 h-80 md:h-card_432px">
                        <p className="text-lg sm:text-2xl lg:text-4xl uppercase sm:w-online_ordering_card">{text}</p>
                    </div>                    
                </div>
            </Link>
        </Fade>
    );
}
