import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import React from 'react'
import { Link, useHistory } from "react-router-dom";
import { useState } from 'react';
import { Formik } from 'formik';

export default function AdminSearchForm({color, crumb_color, heading, header_color, breadcrumbs}) {

    const [searchValue, setSearchValue] = useState("")
    let history = useHistory();

    let headStyle = `w-full mx-auto ${header_color}`
    let crumbs = `text-center font-bold uppercase px-8 sm:px-0 text-xs ${crumb_color}`
    let search = `${crumb_color} uppercase text-center lg:pr-10`
    let headingStyle = `${color} text-4xl sm:text-5xl lg:text-6xl xl:text-7xl text-center capitalize`
    return (
        <div className={headStyle}>
            {/* <br/> */}

            {/* <Formik
                initialValues={{ searchBox: ''}}
                onSubmit={(values, { setSubmitting }) => {
                    history.push({pathname: '/search-results', state: searchValue})
                }}
                >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="lg:flex lg:items-center lg:justify-center">
                            <p className ={search}>Search</p>
                            <div className="relative text-center">
                                <div className="absolute top-3 left-16 lg:top-3 lg:left-3"> 
                                <FontAwesomeIcon 
                                    className={"text-gray-400 cursor-pointer text-base"}
                                    icon={faSearch}
                                /> 
                                </div> 
                                <input type="text" name="searchBox" className="lg:w-96 sm:w-48 pl-10 pr-20 rounded-lg z-0 focus:shadow focus:outline-none" onChange={(values) => setSearchValue(values.target.value)} />

                            </div>

                            <button type="submit" disabled={isSubmitting} loading={isSubmitting} className="inline-flex items-center px-12 py-2 ml-32 lg:ml-3 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_red hover:bg-lk_red_hover mt-4 lg:mt-0">
                                GO &nbsp;{'>'}
                            </button>
                        </div>
                    </form>
                )}
                </Formik> */}
                
                    
            {/* <br/>

            <h2 className ={headingStyle}>{heading}</h2>
            <br/>

            <div className={crumbs}>
                {breadcrumbs.map((item) => (
                    <span key={item.name}>
                        {item.count > 1 && (
                            <span>{'  >  '}</span>
                        )}
                        <Link
                            key={item.name}
                            as="a"
                            to={item.href}
                            aria-current={item.current ? "page" : undefined}
                            className="hover:text-lk_dark_grey_button_hover"
                        >
                            {item.name}
                        </Link>
                    </span>
                ))}
            </div>
            <br/> */}
        </div>
    )
}