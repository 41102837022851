import { Formik, Form } from "formik";
import axios from "axios";
import React from 'react'
import { useHistory, Link } from "react-router-dom";
import * as Yup from 'yup';
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import CustomButton from "../../../../app/common/form/CustomButton";
import CustomText from "../../../../app/common/form/CustomText";
import AdminSearchForm from "../../../forms/AdminSearchForm";
import AdminNav from "../../../nav/AdminNav";
import Select from 'react-select'
import { usePagination, useTable, useSortBy } from 'react-table';

export function EnquiriesHistory(props) {
    let history = useHistory();
    const { currentUser } = useSelector((state) => state.auth);

    const [cartTotal, setCartTotal] = useState([])
    const [enquiries, setEnquiries] = useState([])

    useEffect(() => {
        setCartTotal(parseFloat(window.sessionStorage.getItem("itemsInBasket")));
        getEnquiries();
    }, []);

    async function getEnquiries(){
        let allEnquiries = await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}all-enquiries`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
        });
    
        let enqData = allEnquiries.data;
        let enquiriesData = [];
    
        await Object.keys(enqData).forEach(function (key) {
            enquiriesData.push({
                col0: enqData[key].id,
                col1: enqData[key].first_name,
                col2: enqData[key].last_name,
                col3: enqData[key].email,
                col4: enqData[key].subject,
            });
        })
    
        setEnquiries(enquiriesData)
    }

    const data = React.useMemo(() => enquiries, [enquiries])

    const columns = React.useMemo(
        () => [
          {
            Header: () => (
                <div
                  style={{
                    textAlign:"left"
                  }}
                >First Name</div>),
            accessor: 'col1',
          },
          {
            Header: () => (
                <div
                  style={{
                    textAlign:"left"
                  }}
                >Last Name</div>),
            accessor: 'col2',
          },
          {
            Header: () => (
                <div
                  style={{
                    textAlign:"left"
                  }}
                >Email Address</div>),
            accessor: 'col3',
          },
          {
            Header: () => (
                <div
                  style={{
                    textAlign:"left"
                  }}
                >Subject</div>),
            accessor: 'col4',
          },
          {
            Header: '',
            accessor: 'col0',
            Cell: rowInfo => {
                return (
                    <div>
                        
                        <Link
                            as="a"
                            to={"/enquiries-view/"+rowInfo.row.original.col0}
                            className="hover:text-lk_dark_grey_button_hover"
                        >
                            <button className="px-10 py-2 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase mx-auto">
                                View Enquiry
                            </button>
                        </Link>
                    </div>
                );
              },
          },
         
        ],
        []
      )

      const tableInstance = useTable({ columns, data }, useSortBy, usePagination)

      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        prepareRow,
      } = tableInstance


    return (
        <>
        <div className="bg-white">

            <AdminSearchForm
                heading={"Enquiries History"}
                color="text-gray-500"
                header_color="bg-gradient-to-b from-gray-300"
                crumb_color="text-black"
                breadcrumbs={[
                    { name: "Home", href: "/", count: 1 },
                    { name: "Enquiries History", href: "/enquiries-history", count: 2 },
                ]}
            />

            {/* <AdminNav cartTotal={cartTotal}/> */}
            <br/><br/>

        </div>

        <div className="container mx-auto">

                    

        <table {...getTableProps()} className="mb-10 w-full" style={{borderCollapse: "separate", borderSpacing: "0 1em"}}>
            <thead>
            {
            headerGroups.map(headerGroup => (
                
                <tr {...headerGroup.getHeaderGroupProps()} className="bg-gray-100">
                {
                headerGroup.headers.map(column => (
                    
                    <th {...column.getHeaderProps(column.getSortByToggleProps())} className="px-2 py-5 font-light">
                    {column.render('Header')}
                    </th>
                ))}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {
            page.map(row => {
                
                prepareRow(row)
                return (
                
                <tr {...row.getRowProps()} className="bg-white rounded mt-5">
                    {
                    row.cells.map(cell => {
                    
                    return (
                        <td {...cell.getCellProps()} style={{borderRight: "1px solid #EFEFEF", textAlign: "left"}} className="mx-auto text-center relative px-2 py-2 text-sm">
                            {cell.render('Cell')}
                        </td>
                    )
                    })}
                </tr>
                )
            })}
            </tbody>
        </table>

            <nav
                className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                aria-label="Pagination"
            >
                <div className="flex-1 flex justify-between sm:justify-end">
                    <button
                        className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                    >
                        Previous
                    </button>
                    <button
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                        className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                        Next
                    </button>
                </div>
            </nav>


        </div>
    </>
    )    
}